<template>
  <div class="sortable-wrapper">
    <slot></slot>
  </div>
</template>

<script>
import Sortable, { MultiDrag } from "sortablejs";

Sortable.mount(new MultiDrag());

function setDraggingItems(evt, draggingItems) {
  evt.item._dragging_items_ = [...draggingItems];
}

function getDraggingItems(evt) {
  return [...(evt.item._dragging_items_ ?? [])];
}

function clearDraggingItems(evt) {
  evt.item._dragging_items_ = null;
}

export default {
  name: "SortableWrapper",
  props: {
    option: Object,
    label: String,
    value: Array,
  },
  data() {
    return {
      // 描画後に初期化する
      sortable: null,
      draggingItems: [],
      selectedElms: [],
      selectedNewIndexes: [],
    };
  },
  mounted() {
    this.$nextTick(this.refresh);
  },
  updated() {
    // 選択された状態で新しくリストに追加された要素がある場合、選択が解除されているため、改めて選択状態にする
    const elms = this.$scopedSlots.default?.().map(({ elm }) => elm);
    for (const newIndex of this.selectedNewIndexes) {
      Sortable.utils.select(elms[newIndex]);
    }
    this.selectedNewIndexes = [];
  },
  computed: {
    dragList() {
      return [...(this.$props.value ?? [])];
    },
    defaultOption() {
      const self = this;
      return {
        onStart: function (evt) {
          const draggingIndexes =
            evt.oldIndicies.length === 0
              ? [evt.oldIndex]
              : evt.oldIndicies.map(({ index }) => index).filter((index) => index >= 0);

          const draggingItems = draggingIndexes.map((index) => self.dragList[index]);
          setDraggingItems(evt, draggingItems);
        },
        onAdd: function (evt) {
          const draggingItems = getDraggingItems(evt);
          self.insertElms(evt);

          self.removeElms(evt);

          const newIndex =
            evt.newIndicies.length === 0
              ? evt.newIndex
              : evt.newIndicies.map(({ index }) => index).find((index) => index >= 0);

          const newItems = self.dragList.toSpliced(newIndex, 0, ...draggingItems);
          self.emit(newItems);

          // DOM更新時にドラッグして追加された要素を選択状態にするため、新しい要素のidnexを保持する
          self.selectedNewIndexes = evt.newIndicies
            .map(({ index }) => index)
            .filter((index) => index >= 0);

          self.$nextTick(() => {
            // 古い要素が選択されたままになっているため、選択を解除する
            for (const elm of evt.items) {
              Sortable.utils.deselect(elm);
            }
          });
        },
        onUpdate: function (evt) {
          const draggingItems = getDraggingItems(evt);
          self.removeElms(evt);
          self.insertElms(evt);

          const updatedItems = self.dragList;

          const oldIndexes =
            evt.oldIndicies.length === 0
              ? [evt.oldIndex]
              : evt.oldIndicies.map(({ index }) => index).sort((i1, i2) => i2 - i1);

          for (const i of oldIndexes) {
            updatedItems.splice(i, 1);
          }

          const newIndex =
            evt.newIndicies.length === 0
              ? evt.newIndex
              : evt.newIndicies.map(({ index }) => index).find((index) => index >= 0);

          updatedItems.splice(newIndex, 0, ...draggingItems);
          self.emit(updatedItems);
        },
        onRemove: function (evt) {
          self.insertElms(evt);
          const removedItemIndexes =
            evt.oldIndicies.length === 0
              ? [evt.oldIndex]
              : evt.oldIndicies
                  .map(({ index }) => index)
                  .filter((index) => index >= 0)
                  .sort((i1, i2) => i2 - i1);

          const newItems = self.dragList;
          for (const i of removedItemIndexes) {
            newItems.splice(i, 1);
          }

          self.emit(newItems);
        },
        onEnd: function (evt) {
          clearDraggingItems(evt);
        },
      };
    },
  },
  methods: {
    refresh() {
      if (this.sortable) {
        return;
      }

      const elm = this.$el;
      if (!elm.classList.contains("sortable-wrapper")) {
        return;
      }

      this.sortable = Sortable.create(elm, {
        ...this.defaultOption,
        ...(this.option ?? {}),
      });
    },
    emit(newValue) {
      this.$nextTick(() => {
        this.$emit("input", newValue);
      });
    },
    removeElms(evt) {
      if (evt.items.length) {
        const items = evt.items.map((elm) => ({
          parent: elm.parentElement,
          node: elm,
        }));
        for (const { parent, node } of items) {
          parent.removeChild(node);
        }
      } else {
        evt.item.parentElement?.removeChild(evt.item);
      }

      if (evt.clones.length) {
        for (const clone of evt.clones) {
          clone.parentElement?.removeChild(clone);
        }
      }
    },
    insertElms(evt) {
      const insertElm = (oldIndex, from, node) => {
        if (oldIndex < from.children.length) {
          from.insertBefore(node, from.children[oldIndex]);
        } else {
          from.appendChild(node);
        }
      };

      const fromElm = evt.from;
      if (evt.items.length) {
        for (const { multiDragElement, index } of evt.oldIndicies) {
          if (index >= 0) {
            insertElm(index, fromElm, multiDragElement);
          }
        }
      } else {
        insertElm(evt.oldIndex, fromElm, evt.item);
      }
    },
  },
  beforeDestroy() {
    this.sortable?.destroy();
    this.sortable = null;
  },
};
</script>

<style scoped>
.sortable-wrapper {
  height: 100%;
}
</style>
