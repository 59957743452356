<template>
  <div class="d-flex align-start flex-nowrap pt-4">
    <v-text-field
      outline
      dense
      @focus="focus = true"
      @blur="blurEvent($event)"
      v-model="textFieldDate"
      :label="label"
      clear-icon="mdi-close-circle"
      :clearable="clearable"
      :rules="[dateRule]"
      @update:error="$emit('update:error', $event)"
    ></v-text-field>
    <span v-if="required" class="require asterisk-spacer">*</span>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="-100"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text x-small class="px-1">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>
      <v-date-picker v-model="pickerDate" no-title @input="dateMenu = false"></v-date-picker>
    </v-menu>
    <v-btn id="btn-yesterday" class="px-1" @click="previousDate" text x-small>
      <v-icon size="20">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn id="btn-tomorow" class="px-1" @click="nextDate" text x-small>
      <v-icon size="20">mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";

export default {
  name: "DatePicker",
  props: {
    value: String,
    label: String,
    clearable: { type: Boolean, default: true },
    required: { type: Boolean, default: false, required: false },
  },
  computed: {
    textFieldDate: {
      get() {
        return this.$props.value?.replaceAll("-", "/") ?? "";
      },
      set(newDate) {
        // テキスト入力中は更新しない
        if (!this.focus) {
          this.$emit("input", newDate?.replaceAll("/", "-") ?? "");
        }
      },
    },
    pickerDate: {
      get() {
        return this.$props.value?.replaceAll("/", "-") ?? "";
      },
      set(newDate) {
        this.$emit("input", newDate);
      },
    },
  },
  data() {
    return {
      dateMenu: false,
      errorMessage: this.$t("check.chk_inputDate"),
      focus: false,
      dateRule: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        this.errorMessage,
    };
  },
  methods: {
    previousDate() {
      if (!this.textFieldDate) {
        return;
      }

      if (this.dateRule(this.textFieldDate) !== true) {
        return;
      }

      const splitDate = this.textFieldDate.split("/");
      const date = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
      this.textFieldDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDate() {
      if (!this.textFieldDate) {
        return;
      }

      if (this.dateRule(this.textFieldDate) !== true) {
        return;
      }

      const splitDate = this.textFieldDate.split("/");
      const date = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
      this.textFieldDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    blurEvent(event) {
      this.focus = false;
      this.$emit("input", event.target.value?.replaceAll("/", "-") ?? "");
    },
  },
};
</script>

<style scoped></style>
