<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_inquiryList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row" align="center">
            <!-- ラジオボタン -->
            <v-col style="max-width: fit-content">
              <v-radio-group v-model="radioSelected" row>
                <v-radio label="送り状番号" :value="this.invNumRadioVal"></v-radio>
                <v-radio label="お客様問い合わせ番号" :value="this.inquiryNoRadioVal"></v-radio>
              </v-radio-group>
            </v-col>
            <!--ボタン領域-->
            <v-col class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getFreightConditions()">{{
                  $t("btn.btn_freightInquiry")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid class="main-area">
        <v-data-table
          id="inquiryListData"
          fixed-header
          :headers="headerItems"
          :items="freightProgressListForInvNum"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          v-if="radioSelected === this.invNumRadioVal"
        >
          <!-- 送り状番号 -->
          <template v-slot:[`item.invNumSid`]="{ item }">
            <div>
              <v-text-field
                outlined
                dense
                v-model="item.invNumSid"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
          </template>

          <!-- 問合せ番号 -->
          <template v-slot:[`item.inquiryNo`]="{ item }">
            <div>{{ item.inquiryNo }}</div>
          </template>

          <!-- 便情報一覧 -->
          <template v-slot:[`item.progress`]="{ item }">
            <ProgressItem
              :progress="item.progress"
              @input-slider="sliderHandleInput"
            ></ProgressItem>
          </template>
        </v-data-table>
        <v-data-table
          id="inquiryListData"
          fixed-header
          :headers="headerItems"
          :items="freightProgressListForInquiryNo"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          v-else
        >
          <!-- 送り状番号 -->
          <template v-slot:[`item.invNumSid`]="{ item }">
            <div>{{ item.invNumSid }}</div>
          </template>

          <!-- 問合せ番号 -->
          <template v-slot:[`item.inquiryNo`]="{ item, index }">
            <div>
              <template v-if="index === 0">
                <v-text-field
                  outlined
                  dense
                  v-model="item.inquiryNo"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </template>
              <template v-else>
                <span>{{ item.inquiryNo }}</span>
              </template>
            </div>
          </template>

          <!-- 便情報一覧 -->
          <template v-slot:[`item.progress`]="{ item }">
            <ProgressItem
              :progress="item.progress"
              @input-slider="sliderHandleInput"
            ></ProgressItem>
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :title="confirmDialog.title"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
        :cancelBtnFlg="confirmDialog.cancelBtnFlg"
        :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
      />
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import commonRules from "@/mixins/CommonRules";
import ProgressItem from "./ProgressItem.vue";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "../../components/SimpleDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "InquiryListInside",
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
    ProgressItem,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
    inputNum: String,
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    openMenu: false,
    invNumRadioVal: "invNumRadioVal",
    inquiryNoRadioVal: "inquiryNoRadioVal",
    radioSelected: "",
    // 一覧
    freightProgressListForInvNum: [],
    freightProgressListForInquiryNo: [],
    // ヘッダ
    headerItems: [
      // 件数
      { text: "", value: "no", width: "85px", align: "left", sortable: false },
      // 送り状番号
      {
        text: i18n.tc("label.lbl_invNumSid"),
        value: "invNumSid",
        width: "275px",
        align: "left",
        sortable: false,
      },
      // お客様問合せ番号
      {
        text: i18n.tc("label.lbl_inquiryNo"),
        value: "inquiryNo",
        width: "275px",
        align: "left",
        sortable: false,
      },
      // progressBar
      { text: "", value: "progress", minwidth: "600px", align: "left", sortable: false },
    ],
    initInquiryData: {
      pickupScheduleDate: "",
      deliveryScheduleDate: "",
      smallLotsQuantity: "",
      scaleLabels: {},
      statusNo: "0",
      totalScaleNo: 0,
    },
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
  }),

  created() {},

  methods: {
    /**
     * 初期化
     */
    init() {
      // 送り状番号の初期表示は送り状番号を選択する
      this.radioSelected = this.invNumRadioVal;
      this.invNumInit();
      this.inquiryNoInit();
    },
    invNumInit() {
      // 初期表示は固定で10要素を指定
      const initList = [...Array(10)].map((_, i) => ({
        no: i + 1 + "件目",
        invNumSid: "",
        inquiryNo: "",
        progress: this.initInquiryData,
      }));
      this.freightProgressListForInvNum = initList;
    },
    inquiryNoInit() {
      // 問合せ番号の初期表示は固定で1要素を指定
      this.freightProgressListForInquiryNo = [
        {
          no: "1件目",
          invNumSid: "",
          inquiryNo: "",
          progress: this.initInquiryData,
        },
      ];
    },

    // ラジオボタンの切替
    clickRadio(value) {
      // 送り状の場合
      if (value === this.invNumRadioVal) {
        // 10レコード表示されるよう要素を作成
      }
      // 問合せ番号の場合
      else if (value === this.inquiryNoRadioVal) {
        // 1レコード表示のみ要素を作成
      }
    },
    // つまみの進捗を設定
    sliderHandleInput(handleValue) {
      this.value = handleValue;
    },
    /**
     * 貨物状況取得
     */
    getFreightConditions() {
      const config = this.$httpClient.createGetApiRequestConfig();
      let invNumList = [];
      let inquiryNo = "";
      // 送り状番号で検索
      if (this.radioSelected === this.invNumRadioVal) {
        this.inquiryNoInit();
        // テキストボックスの値取得
        invNumList = this.freightProgressListForInvNum
          .map((el) => el.invNumSid)
          .filter((value) => value && value.trim() !== "");
        // 送り状に入力がない場合画面を初期化してメッセージを返却
        if (invNumList.length === 0) {
          this.infoDialog.message = messsageUtil.getMessage("P-TRN-001_002_E");
          this.infoDialog.title = appConfig.DIALOG.confirm;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          this.invNumInit();
          return;
        }

        // 送り状番号リストを設定
        config.params.invNumSidList = invNumList;
      }
      // 問合せ番号で検索
      else if (this.radioSelected === this.inquiryNoRadioVal) {
        this.invNumInit();
        inquiryNo = this.freightProgressListForInquiryNo[0].inquiryNo;

        // 問合せ番号に入力がない場合画面を初期化してメッセージを返却
        if (!inquiryNo) {
          this.infoDialog.message = messsageUtil.getMessage("P-TRN-001_003_E");
          this.infoDialog.title = appConfig.DIALOG.confirm;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          this.inquiryNoInit();
          return;
        }
        // 問合せ番号設定
        config.params.inquiryNumber = inquiryNo;
      }
      // APIから値取得
      // ---------------------------------------------------------------------
      // 接続先のAPI_URLを入力
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_PICKUP_DELIVERY_STATUTS_INSIDE_SEARCH, config)
          .then((response) => {
            const data = response.data;
            if (data.resCom.resComCode !== "000") {
              const message =
                data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
            const progressData = data.resIdv.pickupDeliveryStatusListInside;

            // 送り状番号で検索された場合
            if (this.radioSelected === this.invNumRadioVal) {
              this.invNumInit();
              const invNumList = config.params.invNumSidList;
              // 入力された送り状ごとに処理
              for (let i = 0; i < invNumList.length; i++) {
                // 送り状番号を検索
                const inquiryData = progressData.find(
                  ({ invNumSid }) => invNumSid === invNumList[i]
                );

                // 一致する送り状番号がある場合データを設定
                if (inquiryData) {
                  this.freightProgressListForInvNum[i].invNumSid = invNumList[i];
                  this.freightProgressListForInvNum[i].inquiryNo = inquiryData.inquiryNumber;
                  this.freightProgressListForInvNum[i].progress =
                    this.formatProgressData(inquiryData);
                }
                // 一致する送り状番号がない場合、問合せ番号・進捗にメッセージを表示
                else if (invNumList[i]) {
                  this.freightProgressListForInvNum[i].invNumSid = invNumList[i];
                  this.freightProgressListForInvNum[i].inquiryNo = "";
                  this.freightProgressListForInvNum[i].progress = {
                    invNumSid: invNumList[i], // 送り状番号
                    inquiryNo: "", // 問合せ番号
                    exsistMessage: messsageUtil.getMessage("P-TRN-001_001_E"),
                    totalScaleNo: 0, // ラベルの総数
                  };
                }
                // 空白の場合、問合せ番号・進捗を初期化
                else {
                  this.freightProgressListForInvNum[i].inquiryNo = "";
                  this.freightProgressListForInvNum[i].progress = this.initInquiryData;
                }
              }
            }
            // 問合せ番号で検索
            else {
              this.inquiryNoInit();
              // データがある場合
              if (progressData && progressData.length > 0) {
                let InquiryNoSearchList = [];
                // データを整形して表示できるよう修正
                for (let i = 0; i < progressData.length; i++) {
                  InquiryNoSearchList.push({
                    no: i + 1 + "件目",
                    invNumSid: progressData[i].invNumSid,
                    inquiryNo: progressData[i].inquiryNumber,
                    progress: this.formatProgressData(progressData[i]),
                  });
                }
                this.freightProgressListForInquiryNo = InquiryNoSearchList;
              }
              // データない場合、送り状番号を初期化、進捗にメッセージを表示
              else {
                this.freightProgressListForInquiryNo = [
                  {
                    no: "1件目",
                    invNumSid: "",
                    inquiryNo: inquiryNo,
                    progress: {
                      invNumSid: "", // 送り状番号
                      inquiryNo: inquiryNo, // 問合せ番号
                      exsistMessage: messsageUtil.getMessage("P-TRN-001_001_E"),
                      totalScaleNo: 0, // ラベルの総数
                    },
                  },
                ];
              }
            }
            resolve(response);
          })
          .catch((ex) => {
            this.infoDialog.message = ex.message ?? ex;
            this.infoDialog.title = appConfig.DIALOG.confirm;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    /**
     * 文字列型の日時をUTCからJSTへ変更し、指定したフォーマットを行う
     */
    formatDate(date, format) {
      return date
        ? dateTimeHelper.toStringDate(
            format,
            dateTimeHelper.convertJstDateTime(dateTimeHelper.convertUTC2JST(date))
          )
        : null;
    },

    /**
     * 進捗状況実績を表示データとしてフォーマット
     */
    formatProgressData(inquiryData) {
      let progressData = {
        invNumSid: inquiryData.invNumSid, // 送り状番号
        inquiryNo: inquiryData.inquiryNumber, // 問合せ番号
        pickupScheduleDate: this.formatDate(inquiryData.pickupScheduleDate, "YYYY/MM/DD"), // 集荷予定日
        deliveryScheduleDate: this.formatDate(inquiryData.deliveryScheduleDate, "YYYY/MM/DD"), // 配達予定日
        smallLotsQuantity: inquiryData.smallLotsQuantity, // 小口数
        message: inquiryData.message,
        statusNo: inquiryData.pickupDeliveryActList?.length - 1 ?? 0, // 進捗ポイント（アイコンがどこまで進むか）
        scaleLabels: inquiryData.statusLabels,
        totalScaleNo: inquiryData.statusLabels.length - 1, // ラベルの総数
      };
      for (let pickupDeliveryAct of inquiryData.pickupDeliveryActList) {
        pickupDeliveryAct.actualDtm = this.formatDate(
          pickupDeliveryAct.actualDtm,
          "YYYY/MM/DD hh:mm"
        );
      }
      progressData.pickupDeliveryActList = inquiryData.pickupDeliveryActList;
      return progressData;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.title = appConfig.DIALOG.confirm;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.cancelBtnFlg = true;
      this.confirmDialog.outsideClickNotCloseFlg = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期化
      this.init();
      this.confirmDialog.isOpen = false;
    },
  },

  computed: {},
  watch: {},
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#inquiryListData ::v-deep {
  .v-data-table__wrapper {
    overflow-x: hidden;
  }
  th,
  td {
    padding: 7px 20px;
  }
  th:last-child {
    min-width: 600px;
  }
}
.table-container {
  max-height: calc(100vh - 50px);
  display: block;
}
.table-container .v-data-table {
  overflow: hidden; /* 横スクロールを非表示にする（必要に応じて調整） */
}
</style>
