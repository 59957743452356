<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-container fluid>
      <!--ボタン領域-->
      <v-row style="margin-left: 3px">
        <div class="search-row-exeBtn pt-2">
          <!-- 詳細内容 -->
          <div style="float: left">
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_close')">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
          <div style="float: right">
            <!-- 追加ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_add')"
              :disabled="lastListFlg == true"
              >{{ $t("btn.btn_add") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- 入力フォーム -->
    <v-form ref="editList" lazy-validation>
      <!-- コンテナ -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          height="750px"
        >
          <template v-slot:[`item.productName`]="{ item, index }" style="padding: 0 5px">
            <!-- 品名-->
            <v-text-field
              class="text-box"
              outlined
              dense
              v-model="item.productName"
              maxlength="100"
              clear-icon="mdi-close-circle"
              clearable
              style="width: 200px"
              :ref="'cursorProcd' + index"
              :rules="[$inputRules.isRequiredRow(headerItems, item)]"
              :error-messages="item.requiredItemCheckMsg"
            />
          </template>

          <template v-slot:[`item.packing`]="{ item }">
            <!-- 荷姿-->
            <v-autocomplete
              v-model="item.packing"
              :items="packingNacCdList"
              :search-input.sync="item.search"
              dense
              :error-messages="item.requiredItemCheckMsg"
            >
              <option disabled value="item.packing"></option>
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
              <!-- * -->
            </v-autocomplete>
          </template>

          <template v-slot:[`item.weight`]="{ item, index }">
            <!-- 重量-->
            <v-text-field
              class="input-number"
              outlined
              dense
              v-model="item.weight"
              clear-icon="mdi-close-circle"
              clearable
              :rules="[
                rules.isNumber,
                rules.inputNumber93,
                rules.inputNumberOverZero(item.weight, true),
              ]"
              @click="clickPop(item.weight)"
              @change="changeWeight(item, index)"
              :error-messages="item.requiredItemCheckMsg"
              :ref="'weightCheck' + index"
            />
          </template>
          <!-- サイズ -->
          <template v-slot:[`item.freightSizeL`]="{ item, index }">
            <div id="table-size" class="d-flex flex-row">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.freightSizeL"
                clear-icon="mdi-close-circle"
                clearable
                style="width: 180px"
                persistent-hint
                :rules="[rules.isNumber, rules.inputNumber92, rules.inputNumberOverZero]"
                @click="clickPop(item.freightSizeL)"
                @change="changeSize(item, index, 'L')"
                :error-messages="item.requiredItemCheckMsg"
                :ref="'sizeLCheck' + index"
              />
              <div style="font-size: 30px; padding: 9px 2px 0 2px">×</div>
            </div>
          </template>
          <template v-slot:[`item.freightSizeW`]="{ item, index }">
            <div id="table-size" class="d-flex flex-row">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.freightSizeW"
                clear-icon="mdi-close-circle"
                clearable
                style="width: 180px"
                persistent-hint
                :rules="[rules.isNumber, rules.inputNumber92, rules.inputNumberOverZero]"
                @click="clickPop(item.freightSizeW)"
                @change="changeSize(item, index, 'W')"
                :error-messages="item.requiredItemCheckMsg"
                :ref="'sizeWCheck' + index"
              />
              <div style="font-size: 30px; padding: 9px 2px 0 2px">×</div>
            </div>
          </template>
          <template v-slot:[`item.freightSizeH`]="{ item, index }">
            <div id="table-size" class="d-flex flex-row">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.freightSizeH"
                clear-icon="mdi-close-circle"
                clearable
                style="width: 180px"
                persistent-hint
                :rules="[rules.isNumber, rules.inputNumber92, rules.inputNumberOverZero]"
                @click="clickPop(item.freightSizeH)"
                @change="changeSize(item, index, 'H')"
                :error-messages="item.requiredItemCheckMsg"
                :ref="'sizeHCheck' + index"
              />
            </div>
          </template>

          <!-- 小口数 -->
          <template v-slot:[`item.smallLotsQuantity`]="{ item, index }">
            <div id="table-smallQt">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.smallLotsQuantity"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck0, rules.checkQuantity]"
                @click="clickPop(item.smallLotsQuantity)"
                @change="changeSmallLotsQuantity(item, index)"
                :error-messages="item.requiredItemCheckMsg"
                :ref="'smallLotsQtyCheck' + index"
              />
            </div>
          </template>

          <template v-slot:[`item.transportRequirementEdit`]="{ item, index }">
            <!-- 輸送要件編集ボタン-->
            <template>
              <v-btn small @click="openTransportRequirement(item, index)" text class="pen-icon">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.transportRequirement`]="{ item }">
            <!-- 輸送要件-->
            <v-container class="d-flex opt-hist-list-container">
              <template v-for="(transportReqItem, index) in transportRequirementList">
                <!-- 選択した輸送要件を表示 -->
                <div
                  class="flex-item"
                  v-if="item.transportRequirement.includes(transportReqItem.value)"
                  :key="index"
                  style="white-space: nowrap"
                >
                  {{ transportReqItem.text }} /&nbsp;
                </div>
              </template>
            </v-container>
          </template>

          <template v-slot:[`item.remarks`]="{ item }">
            <!-- 備考-->
            <v-text-field
              class="text-box"
              outlined
              dense
              v-model="item.remarks"
              maxlength="200"
              clear-icon="mdi-close-circle"
              clearable
              style="width: 200px"
            />
          </template>

          <template v-slot:[`item.deleteData`]="{ item }">
            <!-- 削除-->
            <v-btn small @click="deleteItem(item)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-form>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
    <!-- 輸送要件選択ダイアログ-->
    <v-dialog
      v-model="dialogTransportRequirementsBreakdown"
      :max-width="800"
      persistent=""
      no-click-animation
    >
      <v-card>
        <v-card-title id="sub-bar">
          <span id="lbl-screen-name">{{ $t("label.lbl_transportRequirementSelect") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <!-- 輸送要件選択テーブルデータ -->
            <v-data-table
              id="workInstructionsData"
              fixed-header
              :headers="transportRequirementsHeader"
              :items="transportRequirementList"
              disable-sort
              :hide-default-footer="true"
              :items-per-page="10"
            >
              <!-- 一覧チェックボックス -->
              <template v-slot:[`item.check`]="{ item }">
                <input
                  type="checkbox"
                  v-model="item.check"
                  :value="listCheckbox"
                  style="transform: scale(2)"
                  :disabled="item.disabledFlg"
                />
              </template>
              <!-- 輸送要件 -->
              <template v-slot:[`item.transportRequirement`]="{ item }">
                <span>
                  <!-- 輸送要件を表示 -->
                  {{ item.text }}
                </span>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--ボタン領域-->
          <div class="search-row-exeBtn">
            <!-- 閉じるボタン領域 -->
            <div class="btn-search-area" style="float: left">
              <v-btn class="other-btn" @click="closeDialogTransportRequirements">
                {{ $t("btn.btn_close") }}
              </v-btn>
            </div>
            <div class="btn-area">
              <!-- 設定ボタン -->
              <v-btn class="other-btn" @click="setTransportRequirements">
                {{ $t("btn.btn_config") }}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "@/components/loading"; // ローディング画面
import ConfirmDialog from "@/components/ConfirmDialog"; // 確認ダイアログ
import SimpleDialog from "@/components/SimpleDialog"; // シンプルダイアログ
import { i18n } from "@/lang/lang.js"; // 多言語対応
import { appConfig } from "@/assets/scripts/js/AppConfig"; // アプリ設定
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";
import { commonFunction } from "@/assets/scripts/js/CommonFunction";

export default {
  // 表示名
  name: "TransportListDialog",

  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
    viewDataList: { type: Array, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    listLength: { type: Number, default: 0 },
    lotPullList: { type: Array, default: null },
    screenId: { type: String, default: null },
    packingNacCdList: { type: Array, default: null },
    transportRequirementList: { type: Array, default: null },
  },

  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    inputList: [],
    dialogTransportRequirementsBreakdown: false,
    transportRequirementsHeader: [
      { value: "check", align: "left", width: "10%" },
      {
        text: i18n.tc("label.lbl_transportRequirement"),
        value: "transportRequirement",
        align: "left",
        width: "90%",
      },
    ],
    // 作業指示内訳選択行インデックス
    clickRowIndex: 0,

    // 一覧選択チェックボックス
    listCheckbox: [],
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_packing"),
        value: "packing",
        width: "200px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_weight"),
        value: "weight",
        width: "210px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "freightSizeL",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_freightSize"),
        value: "freightSizeW",
        width: "80px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "freightSizeH",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_smallLotsQuantity"),
        value: "smallLotsQuantity",
        width: "150px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "transportRequirementEdit",
        width: "80px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_transportRequirementArrow"),
        value: "transportRequirement",
        width: "250px",
        align: "left",
      },
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        width: "230px",
        align: "center",
      },
      {
        // 削除
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "74px",
        align: "center",
      },
    ],
    defaultItem: {
      No: "",
      productName: "",
      packing: "",
      packingList: [],
      weight: "",
      productHint: "",
      freightSizeL: "",
      freightSizeW: "",
      freightSizeH: "",
      smallLotsQuantity: "",
      transportRequirement: [],
      transportReqMenu: false,
      isAdd: true,
    },
    // 入力チェックエラーダイアログ用フラグ
    lastListFlg: false,
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    focusIdx: 0,
    rules: {
      inputNumber92: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");
            if (numL[0].length > 9 || numL[1].length > 2)
              return i18n.tc("check.chk_inputNumber_9-2");
          } else {
            if (ar.length > 9) {
              return i18n.tc("check.chk_inputNumber_9-2");
            }
          }
        }
        return true;
      },
      checkQuantity: function (value) {
        let isValid;
        if (value != null && value != undefined && value != "") {
          value = String(value).replaceAll(",", "");
          const regex = /^([1-9])(\d{0,4})$/;
          if (regex.test(Number(value))) {
            isValid = true;
          } else {
            isValid = false;
          }
          if (!isValid) {
            return i18n.tc("check.chk_inputNumber_5");
          }
        }
        return true;
      },
      inputNumberOverZero: (value, isWeight) => {
        if (value != null && value != undefined && value != "") {
          let numberVal = value;
          if (numberVal == 0) {
            if (isWeight) {
              return i18n.tc("check.chk_inputMoreOneDotZero");
            } else {
              return i18n.tc("check.chk_inputMoreOneDot");
            }
          }
        }
        return true;
      },
    },
  }),

  methods: {
    // 初期表示処理
    init() {
      this.lastListFlg = false;
      this.focusIdx = this.listLength;
      this.inputList = [];
      for (var j = 0; j < 10; j++) {
        this.defaultItem.No = this.focusIdx + j + 1;
        this.defaultItem.packingList = this.packingNacCdList;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
    },

    /**
     * 入荷予定修正削除画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);
      // No修正
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = this.focusIdx + i + 1;
      }

      this.$refs["weightCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeLCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeWCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeHCheck" + this.editedIndex].resetValidation();
      this.$refs["smallLotsQtyCheck" + this.editedIndex].resetValidation();
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_add":
          if (!this.$refs.editList.validate()) {
            return;
          } else {
            message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_add")]);
            action = this.config;
          }
          break;
        case "btn_close":
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 閉じる処理
     */
    close() {
      this.init();
      this.$emit("update:isOpenDetailDialog", false);
    },

    /**
     * 追加ボタン押下処理
     */
    config() {
      if (this.inputList.length > 0) {
        let sendList = [];
        var no = Number(this.inputList[0].No);
        var i = 0;
        sendList = this.inputList.filter(
          (item) => item.productName != null && item.productName != ""
        );
        sendList.forEach((data) => {
          data.No = no + i;
          i += 1;
        });

        this.$emit("child-event", sendList);
      }

      this.close();
    },

    /**
     *輸送要件選択ダイアログを表示
     */
    openTransportRequirement(item, index) {
      // 輸送要件選択ダイアログのデータ
      for (let i = 0; i < this.transportRequirementList.length; i++) {
        // 選択済みのアイテムのみチェックをつける
        this.transportRequirementList[i].check = false;
        if (item.transportRequirement.includes(this.transportRequirementList[i].value)) {
          this.transportRequirementList[i].check = true;
        }
      }
      //輸送要件選択ボタン選択行インデックス
      this.clickRowIndex = index;
      this.dialogTransportRequirementsBreakdown = true;
    },
    /**
     * 輸送要件選択ダイアログ：閉じる処理
     */
    closeDialogTransportRequirements() {
      this.dialogTransportRequirementsBreakdown = false;
    },
    /**
     * 輸送要件選択ダイアログ：設定処理
     */
    setTransportRequirements() {
      //輸送要件選択ダイアログでチェックが入った行の指示内容を設定
      this.inputList[this.clickRowIndex].transportRequirement = this.transportRequirementList
        .filter((val) => val.check == true)
        .map((val) => {
          return val.value;
        });

      this.dialogTransportRequirementsBreakdown = false;
    },
    /**
     * 受注登録画面：入力カンマ処理 重さ
     */
    changeWeight(val, index) {
      this.inputList[index].weight = commonFunction.getDelimiterFew(val.weight, 9, 3);
    },
    /**
     * 受注登録画面：入力カンマ処理 サイズ
     */
    changeSize(val, index, param) {
      switch (param) {
        case "L":
          this.inputList[index].freightSizeL = commonFunction.getDelimiterFew(
            val.freightSizeL,
            9,
            2
          );
          break;
        case "W":
          this.inputList[index].freightSizeW = commonFunction.getDelimiterFew(
            val.freightSizeW,
            9,
            2
          );
          break;
        case "H":
          this.inputList[index].freightSizeH = commonFunction.getDelimiterFew(
            val.freightSizeH,
            9,
            2
          );
          break;
      }
    },
    /**
     * 受注登録画面：入力カンマ処理 小口数
     */
    changeSmallLotsQuantity(val, index) {
      this.inputList[index].smallLotsQuantity = commonFunction.getDelimiter(
        val.smallLotsQuantity,
        5
      );
    },
    //カンマ削除処理
    clickPop(val) {
      let myName = event.target;
      let pop = val ?? "";
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
  },

  // 監視
  watch: {
    isOpenDetailDialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  // Vueインスタンスが生成された後
  created() {
    // 初期表示処理を実行
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#listData.v-data-table ::v-deep th {
  padding: 0 8px;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-y: hidden;
}

// サイズの入力部分と×の間を狭める
#listData ::v-deep th:nth-child(5),
#listData ::v-deep td:nth-child(5) {
  padding-right: 0px;
}
#listData ::v-deep th:nth-child(6),
#listData ::v-deep td:nth-child(6) {
  padding: 0px;
}
#listData ::v-deep th:nth-child(7),
#listData ::v-deep td:nth-child(7) {
  padding-left: 0px;
}
</style>
