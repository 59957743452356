<template>
  <v-dialog v-model="placeDialog" width="50vw">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>

    <v-card max-height="90vh">
      <v-toolbar dense>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="placeDialog = false"><v-icon> mdi-close </v-icon></v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-row no-gutters>
        <v-col cols="12">
          <GoogleEmbedMap mapMode="place" :params="mapParams" width="100%" height="70vh" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleEmbedMap from "./GoogleEmbedMap.vue";

export default {
  name: "PlaceMapDialog",
  components: {
    GoogleEmbedMap,
  },
  props: {
    dialogTitle: { type: String, default: "" },
    address: { type: String, required: true },
  },
  data() {
    return {
      placeDialog: false,
    };
  },
  computed: {
    mapParams() {
      return {
        q: this.address,
      };
    },
  },
};
</script>
