<template>
  <div :style="[{ width, height }, { maxHeight: '100%' }]" class="visible">
    <v-card
      width="100%"
      height="100%"
      elevation="2"
      rounded="md"
      class="d-flex flex-column justify-start pa-1"
    >
      <v-row no-gutters class="flex-shrink-1 flex-grow-0 mx-n1 mt-n1 mb-1">
        <v-col cols="12" class="text-center" :class="headerColor" style="position: relative">
          <span class="text-subtitle-1 font-weight-medium" :class="headerTextColor">
            {{ headerText }}
          </span>
          <v-btn
            fab
            icon
            absolute
            right
            elevation="2"
            width="24px"
            height="24px"
            class="grey lighten-5 mr-n2 my-auto"
            style="top: 0; bottom: 0"
            @click="$emit('delete-bin', binItem.carSid, binItem.transportSid)"
          >
            <v-icon color="grey darken-3" size="16"> mdi-delete </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-title class="flex-shrink-1 flex-grow-0 pa-0 text-subtitle-1">
        <v-row no-gutters align="start">
          <v-col cols="12" class="d-flex justify-center flex-wrap">
            <div class="text-no-wrap">{{ binItem.carShape }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="pb-0 ma-0 mt-n2">
        <v-row no-gutters align="start">
          <v-col cols="12" class="d-flex justify-center flex-wrap">
            <div class="text-no-wrap">{{ `${binItem.carShago} ${binItem.binNo}便` }}</div>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0 mb-2">
        <v-col class="d-flex align-center justify-center route-button">
          <v-icon size="20" class="map-icon">mdi-map-marker</v-icon>
          <PickupDeliveryMapDialog
            :openLabel="'ルート（参考）'"
            :dialogTitle="`${binItem.carShape} ${binItem.carShago} ${binItem.binNo}便`"
            :origin="officeAddress"
            :destination="officeAddress"
            :waypoints="waypoints"
          >
            <SortableWrapper
              :option="{
                ...dragOptions,
                disabled: (binItem.binStatusDiv ?? '0') !== '0',
              }"
              class="overflow-y-auto overflow-x-hidden pa-1"
              :label="'配車リスト' + binItem.transportSid"
              :value="binItem.dispatchedFreightDtl"
              @input="(newItems) => changeBinItems(newItems)"
            >
              <FreightItem
                v-for="freight in binItem.dispatchedFreightDtl"
                :key="freight.pickupDeliveryDiv + '_' + freight.packingSid"
                :freight="freight"
                :inputDisableFlg="true"
              ></FreightItem>
            </SortableWrapper>
          </PickupDeliveryMapDialog>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0">
        <v-col cols="11" class="d-flex mt-1 driver-input">
          <v-autocomplete
            dense
            :label="$t('label.lbl_driver')"
            v-model="driver"
            height="20"
            hide-details="auto"
            :items="drivers"
            :error="driverError"
            :error-messages="driverErrorMessage"
            item-value="driverUserSid"
            item-text="driverUserName"
            class="text-body-2"
          >
          </v-autocomplete>
          <span class="require asterisk-spacer">*</span>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0">
        <v-col cols="9" class="d-flex justify-center">
          <!-- 便開始時刻 -->
          <div>
            <v-text-field
              dense
              v-model="binScheduleTimeFrom"
              hide-details="auto"
              type="time"
              class="pa-0 ma-0 text-body-2"
              @change="checkTime(binScheduleTimeFrom, binScheduleTimeTo)"
              clear-icon="mdi-close-circle"
              clearable
            ></v-text-field>
          </div>
          <!-- ハイフン -->
          <span class="item-spacer">&nbsp;－&nbsp;</span>
          <!-- 便終了時刻 -->
          <div>
            <v-text-field
              dense
              v-model="binScheduleTimeTo"
              hide-details="auto"
              type="time"
              class="pa-0 ma-0 text-body-2"
              @change="checkTime(binScheduleTimeFrom, binScheduleTimeTo)"
              clear-icon="mdi-close-circle"
              clearable
            ></v-text-field>
          </div>
        </v-col>
        <!-- 空白 -->
        <span class="item-spacer"></span>
        <!-- 備考入力欄 -->
        <v-btn class="note-icon" icon small>
          <BinRemarksInputDialog
            :dialogTitle="`${binItem.carShape} ${binItem.carShago} ${binItem.binNo}便`"
            :carSid="`${binItem.carSid}`"
            :transportSid="`${binItem.transportSid}`"
            :remarks="`${binItem.remarks}` != 'undefined' ? `${binItem.remarks}` : ''"
            :outsideClickNotCloseFlg="true"
            @change-remarks="binRemarks"
          >
            <div class="d-flex overflow-x-auto" style="width: 100%">
              <FreightItem
                v-for="freight in binItem.dispatchedFreightDtl"
                :key="freight.pickupDeliveryDiv + '_' + freight.packingSid"
                :freight="freight"
                :inputDisableFlg="true"
                :showDetail="showBinRemarkFreightDetail"
                @change:showDetail="showBinRemarkFreightDetail = $event"
                class="flex-shrink-0"
                style="width: 25%"
              ></FreightItem>
            </div>
          </BinRemarksInputDialog>
        </v-btn>
      </v-row>
      <v-row no-gutters class="flex-shrink-1 flex-grow-0">
        <v-col cols="12" class="text-center text-body-2 mt-2">
          積込時最大積載量:
          {{
            this.transitoryMaxWeight == 0 || !this.transitoryMaxWeight
              ? 0
              : commonFunction.getDelimiterFew(this.transitoryMaxWeight)
          }}
          <span class="font-italic">kg</span> /
          {{
            binItem.carPayload == 0 || !binItem.carPayload
              ? 0
              : commonFunction.getDelimiterFew(binItem.carPayload)
          }}
          <span class="font-italic">kg</span>
        </v-col>
      </v-row>
      <div v-if="this.overloading" style="font-size: 12px; color: #ff5252; text-align: center">
        {{ $t("check.chk_overloading") }}
      </div>
      <!-- 集荷・配達中または集荷・配達済みの場合は配車できないので、未集荷・未配達の場合のみペアボタンを表示する -->
      <v-row
        v-if="binItem.binStatusDiv === '0'"
        no-gutters
        justify="end"
        class="flex-shrink-1 flex-grow-0 pr-2 py-1"
      >
        <v-btn small @click="$emit('click:pair')" :disabled="!binItem.dispatchedFreightDtl?.length">
          <v-icon left>mdi-arrow-left-bold</v-icon>
          ペア
        </v-btn>
      </v-row>
      <v-divider class="ma-1"></v-divider>
      <v-alert
        v-if="alertMessage !== ''"
        dense
        dark
        color="warning"
        class="mb-1 py-1 px-3"
        style="line-height: normal"
      >
        <div class="d-flex align-center">
          <v-icon small left>mdi-alert</v-icon>
          <span class="text-caption">{{ alertMessage }}</span>
        </div>
      </v-alert>
      <slot name="default" class="flex-grow-1 flex-shrink-0 pa-1"></slot>
    </v-card>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      :screenFlag="confirmDialog.screenFlag"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :changeFlag="confirmDialog.changeFlag"
      :cancelBtnFlg="confirmDialog.cancelBtnFlg"
      :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
    />
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonFunction } from "../../assets/scripts/js/CommonFunction.js";
import PickupDeliveryMapDialog from "./PickupDeliveryMapDialog.vue";
import BinRemarksInputDialog from "./BinRemarksInputDialog.vue";
import FreightItem from "./FreightItem.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import SortableWrapper from "./SortableWrapper.vue";

export default {
  name: "BinItem",
  components: {
    PickupDeliveryMapDialog,
    BinRemarksInputDialog,
    FreightItem,
    ConfirmDialog,
    SortableWrapper,
  },
  props: {
    width: String,
    height: String,
    officeAddress: String,
    drivers: { type: Array, default: () => [] },
    // 便情報
    binItem: { type: Object, required: true },
    operationDate: { type: String, default: "" },
  },
  data() {
    return {
      showBinRemarkFreightDetail: false,
      commonFunction: commonFunction,
      routeDialog: false,
      showConfirmDialog: false,
      confirmDialog: {
        message: "",
        redMessage: "",
        isOpen: false,
        okAction: () => {},
      },
      dateTimeHelper: dateTimeHelper,
      inputDate: this.operationDate,
      binScheduleTimeFrom:
        this.binItem.fromDate != null
          ? dateTimeHelper
              .convertUTC2JST(this.binItem.fromDate)
              .substring(this.binItem.fromDate.indexOf(" ") + 1)
          : "",
      binScheduleTimeTo:
        this.binItem.toDate != null
          ? dateTimeHelper
              .convertUTC2JST(this.binItem.toDate)
              .substring(this.binItem.toDate.indexOf(" ") + 1)
          : "",
      fromDateTime: null,
      toDateTime: null,
    };
  },
  watch: {
    binScheduleTimeFrom() {
      if (this.binScheduleTimeFrom != null) {
        this.fromDateTime = this.inputDate + " " + this.binScheduleTimeFrom;
      } else {
        this.fromDateTime = null;
      }
      this.$emit("change-from", this.binItem.carSid, this.binItem.transportSid, this.fromDateTime);
    },

    binScheduleTimeTo() {
      if (this.binScheduleTimeTo != null) {
        this.toDateTime = this.inputDate + " " + this.binScheduleTimeTo;
      } else {
        this.toDateTime = null;
      }
      this.$emit("change-to", this.binItem.carSid, this.binItem.transportSid, this.toDateTime);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "freight",
        disabled: false,
        ghostClass: "freight-ghost",
        multiDrag: true,
        selectedClass: "selected-freight",
        dragClass: "dragging-freight",
        avoidImplicitDeselect: false,
        multiDragKey: "CTRL",
      };
    },
    confirmDialogOption() {
      return {
        ...this.modifiedConfirmDialogOption,
      };
    },
    headerColor() {
      switch (this.binItem.binStatusDiv ?? "0") {
        case "0":
          return "red accent-1";
        case "1":
          return "blue accent-2";
        case "2":
          return "grey darken-1";
        default:
          return "red accent-1";
      }
    },
    headerTextColor() {
      switch (this.binItem.binStatusDiv ?? "0") {
        case "0":
          return "grey--text text--lighten-5";
        case "1":
          return "grey--text text--lighten-5";
        case "2":
          return "grey--text text--lighten-5";
        default:
          return "grey--text text--lighten-5";
      }
    },
    headerText() {
      switch (this.binItem.binStatusDiv ?? "0") {
        case "0":
          return this.$t("label.lbl_unPickupUnDelivered");
        case "1":
          return this.$t("label.lbl_duringPickupDelivery");
        case "2":
          return this.$t("label.lbl_pickupDeliveryComplete");
        default:
          return this.$t("label.lbl_unPickupUnDelivered");
      }
    },
    alertMessage() {
      switch (this.binItem.binStatusDiv ?? "0") {
        case "1":
          return this.$t("label.lbl_duringPickupDeliveryWarnMessage");
        case "2":
          return this.$t("label.lbl_pickupDeliveryCompleteWarnMessage");
        default:
          return "";
      }
    },
    driver: {
      get() {
        return this.binItem.driverUserSid;
      },
      set(driverSid) {
        this.$emit("change-driver", this.binItem.carSid, this.binItem.transportSid, driverSid);
      },
    },
    driverRules() {
      return {
        required: (value) => {
          if (!value && this.binItem?.dispatchedFreightDtl?.length) {
            return messsageUtil.getMessage("P-DVP-001_001_E");
          }
          return true;
        },
      };
    },
    driverError() {
      return !this.driver && !!this.binItem?.dispatchedFreightDtl?.length;
    },
    driverErrorMessage() {
      return this.driverError ? messsageUtil.getMessage("P-DVP-001_001_E") : "";
    },
    transitoryMaxWeight() {
      let initialWeight = 0;
      // 集荷配達区分が集荷の貨物について荷姿指示SIDのリストを作成
      const pickupPackingSids = new Set(
        this.binItem.dispatchedFreightDtl
          .filter((freight) => freight.pickupDeliveryDiv == "01")
          .map((x) => x.packingSid)
      );
      for (const dispatchedFreight of this.binItem.dispatchedFreightDtl) {
        // 自拠点を出発する際に積み込む貨物の合計重量(initialWeight)を求める
        if (dispatchedFreight.pickupDeliveryDiv == "02") {
          // 集荷配達区分が配達
          if (dispatchedFreight.pickupWayDiv == "01" || dispatchedFreight.pickupWayDiv == "02") {
            // 集荷方法区分が01:自拠点出荷もしくは02:荷主出荷（持込）の場合
            initialWeight += dispatchedFreight.totalWeight;
          } else if (!pickupPackingSids.has(dispatchedFreight.packingSid)) {
            // 同一便内に集荷配達区分が集荷、かつ同じ荷姿指示SIDの貨物が存在しない場合
            initialWeight += dispatchedFreight.totalWeight;
          }
        }
      }
      let calcedWeight = initialWeight;
      let transitoryMaxWeight = initialWeight;
      for (const dispatchedFreight of this.binItem.dispatchedFreightDtl) {
        // 配車エリアの上から順番に貨物の集荷配達区分、集荷方法区分を判定
        if (dispatchedFreight.pickupDeliveryDiv == "01" && dispatchedFreight.pickupWayDiv == "03") {
          // 集荷、かつ荷主出荷（集荷）の場合、initialWeightに貨物重量を足す
          calcedWeight += dispatchedFreight.totalWeight;
        } else if (dispatchedFreight.pickupDeliveryDiv == "02") {
          // 配達の場合、initialWeightから貨物重量を引く
          calcedWeight -= dispatchedFreight.totalWeight;
        }
        // 計算結果とinitialWeightを比較し、より重い方を最大重量とする
        transitoryMaxWeight =
          transitoryMaxWeight > calcedWeight ? transitoryMaxWeight : calcedWeight;
      }
      return transitoryMaxWeight === 0 ? "0" : transitoryMaxWeight;
    },
    overloading() {
      const overloading = this.transitoryMaxWeight > this.binItem.carPayload ? true : false;
      return overloading;
    },
    waypoints() {
      const waypoints = [];

      for (const freight of this.binItem.dispatchedFreightDtl ?? []) {
        let waypoint = "";
        if (freight.address) {
          waypoint = freight.address;
        }
        if (freight.pickupDeliveryPoint) {
          waypoint = `${freight.pickupDeliveryPoint}, ${waypoint}`;
        } else if (waypoint === "") {
          waypoint = freight.area;
        }
        waypoints.push(waypoint);
      }
      return waypoints;
    },
  },
  methods: {
    changeBinItems(newItems) {
      this.$set(this.binItem, "dispatchedFreightDtl", newItems);
      this.$emit("change-freight", newItems, this.binItem.carSid, this.binItem.transportSid);
    },
    /**
     * 便が日を跨ぐものであった場合に確認ダイアログを表示
     */
    checkTime(fromTime, toTime) {
      if (fromTime != "" && toTime != "") {
        const closeAction = () => {};
        const wd = "2000/01/01";
        const date1 = new Date(`${wd} ${fromTime}`);
        const date2 = new Date(`${wd} ${toTime}`);
        if (date1 > date2) {
          this.confirmDialog = {
            message: messsageUtil.getMessage("P-DVP-001_007_W"),
            title: appConfig.DIALOG.confirm,
            screenFlag: true,
            isOpen: true,
            okAction: closeAction,
            outsideClickNotCloseFlg: false,
          };
        }
      }
    },

    /**
     * 変更された便備考を親コンポーネントに返却
     */
    binRemarks(carSid, transportSid, remarks) {
      this.$emit("change-remarks", carSid, transportSid, remarks);
    },
  },
};
</script>
<style>
@import "../../css/style.css";

.driver-input label.v-label {
  font-size: 0.875rem;
}
</style>
<style lang="scss" scoped>
.route-button {
  position: relative;
  right: 10px;
}

.map-icon {
  position: relative;
  bottom: 0px;
  top: 0px;
  margin-top: auto;
  margin-bottom: auto;
  right: 5px;
}

.note-icon {
  position: relative;
  bottom: 0px;
  top: 0px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
