<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_transportAdd" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                :search-input.sync="supplierInput"
                @change="changeSupplier"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDate"
                :label="$t('label.lbl_orderDate')"
                @change="changeDate($event, 'order')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateCal"
                  @input="orderDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate('order')" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 輸送担当営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_transportOffice')"
                :hint="setSrhOffice()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changeOffice"
                :search-input.sync="getOfficeSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- 空白 -->
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 請求No(業務No) -->
            <div class="serch-textbox-arrivalNo first-search-item">
              <v-autocomplete
                dense
                v-model="bussinessNoSelected"
                :items="bussinessNoList"
                :label="$t('label.lbl_billingBussinessNo')"
                :hint="setBussinessNo()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 配車計画作成-->
            <div class="text-label" style="margin-left: 15px">
              {{ $t("label.lbl_dispatchPlanCreat") }}
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 対象/対象外 -->
            <v-btn-toggle
              mandatory
              v-model="isTarget"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
              @change="changeIsTarget"
            >
              <v-btn
                class="paid-btn"
                v-for="item in targetDivList"
                :key="item.value"
                :value="item.value"
                small
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 非公開/公開 -->
            <v-btn-toggle
              mandatory
              v-model="isPrivate"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn
                class="paid-btn"
                v-for="item in privateDivList"
                :key="item.value"
                :value="item.value"
                v-bind:disabled="isDisabledPrivate"
                small
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!--集荷方法区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupDivSelected"
                :items="pickupDivList"
                :label="$t('label.lbl_pickupDiv')"
                class="txt-single"
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @focus="focusPickupDiv"
                @change="changePickupDiv"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 集荷先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPoint"
                :items="pickupPointList"
                :label="$t('label.lbl_pickupPoint')"
                :hint="setSrhPickup()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changePickup"
                :search-input.sync="pickupInput"
                :error-messages="pickupPointAlertMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 拠点登録 -->
            <v-btn color="primary" class="api-btn" disabled>
              {{ $t("btn.btn_deliveryPointInsert") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集荷予定日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDate"
                :label="$t('label.lbl_pickupScheduleDate')"
                @change="changeDate($event, 'pickup')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateCal"
                  @input="pickupScheduleDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate('pickup')" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 集荷時間From-->
            <div>
              <v-text-field
                v-model="pickupScheduleTimeFrom"
                type="time"
                style="padding: 0px; margin: 0px"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <!-- ハイフン -->
            <span class="item-spacer">&nbsp;－&nbsp;</span>
            <!-- 集荷時間To-->
            <div>
              <v-text-field
                v-model="pickupScheduleTimeTo"
                type="time"
                style="padding: 0px; margin: 0px"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="pickupScheduleTimeAlertMessage"
              ></v-text-field>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row class="search-row" style="margin-top: 10px">
            <!--配達方法区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryDivSelected"
                :items="deliveryDivList"
                :label="$t('label.lbl_deliveryDiv')"
                class="txt-single"
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                :error-messages="deliveryDivAlertMessage"
                @change="changeDeliveryDiv"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 配達先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPoint"
                :items="deliveryPointList"
                :label="$t('label.lbl_deliveryPoint')"
                :hint="setSrhDelivery()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changeDelivery"
                :search-input.sync="deliveryInput"
                :error-messages="deliveryPointAlertMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 拠点登録 -->
            <v-btn color="primary" class="api-btn" disabled>
              {{ $t("btn.btn_deliveryPointInsert") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 配達予定日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDate"
                :label="$t('label.lbl_deliveryScheduleDate')"
                @change="changeDate($event, 'delivery')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="deliveryScheduleDateAlertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateCal"
                  @input="deliveryScheduleDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 配達時間From-->
            <div>
              <v-text-field
                v-model="deliveryScheduleTimeFrom"
                type="time"
                style="padding: 0px; margin: 0px"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <!-- ハイフン -->
            <span class="item-spacer">&nbsp;－&nbsp;</span>
            <!-- 配達時間To-->
            <div>
              <v-text-field
                v-model="deliveryScheduleTimeTo"
                type="time"
                style="padding: 0px; margin: 0px"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="deliveryScheduleTimeAlertMessage"
              ></v-text-field>
            </div>
          </v-row>
          <!-- 4段目 -->
          <v-row>
            <!-- お客様問合せ番号 -->
            <div class="search-row-exeBtn first-search-item">
              <div class="add-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="inquiryNumber"
                  :label="$t('label.lbl_inquiryNumber')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <!--画面クリアボタン-->
              <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              <!--ボタンエリア-->
              <div class="btn-area">
                <!-- 追加ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="add">
                  {{ $t("btn.btn_add") }}
                </v-btn>
                <!-- 登録ボタン-->
                <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="trnAdd">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addTransport"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid style="padding-bottom: 0px">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="560px"
          >
            <template v-slot:[`item.productName`]="{ item, index }" style="padding: 0 5px">
              <!-- 品名-->
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.productName"
                maxlength="100"
                clear-icon="mdi-close-circle"
                clearable
                style="width: 200px"
                :ref="'cursorProcd' + index"
                :rules="[$inputRules.isRequiredRow(headerItems, item)]"
                :error-messages="item.listItemsErrorMsg"
              />
            </template>

            <template v-slot:[`item.packing`]="{ item }">
              <!-- 荷姿-->
              <v-autocomplete
                v-model="item.packing"
                :items="packingNacCdList"
                :search-input.sync="item.search"
                dense
                :error-messages="item.listItemsErrorMsg"
              >
                <option disabled value="item.packing"></option>
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <template v-slot:[`item.weight`]="{ item, index }">
              <!-- 重量-->
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.weight"
                clear-icon="mdi-close-circle"
                clearable
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.inputNumber53,
                  rules.inputNumberOverZero(item.weight, true),
                ]"
                @click="clickPop(item.weight)"
                @change="changeWeight(item, index)"
                :error-messages="item.listItemsErrorMsg"
                :ref="'weightCheck' + index"
              />
            </template>
            <!-- サイズ -->
            <template v-slot:[`item.freightSizeL`]="{ item, index }">
              <div class="d-flex flex-row">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeL"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeL)"
                  @change="changeSize(item, index, 'L')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeLCheck' + index"
                />
                <div style="font-size: 30px; padding: 9px 2px 0 2px">×</div>
              </div>
            </template>
            <template v-slot:[`item.freightSizeW`]="{ item, index }">
              <div class="d-flex flex-row">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeW"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeW)"
                  @change="changeSize(item, index, 'W')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeWCheck' + index"
                />
                <div style="font-size: 30px; padding: 9px 2px 0 2px">×</div>
              </div>
            </template>
            <template v-slot:[`item.freightSizeH`]="{ item, index }">
              <div class="d-flex flex-row">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeH"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeH)"
                  @change="changeSize(item, index, 'H')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeHCheck' + index"
                />
              </div>
            </template>

            <!-- 小口数 -->
            <template v-slot:[`item.smallLotsQuantity`]="{ item, index }">
              <div id="table-smallQt">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.smallLotsQuantity"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.checkQuantity,
                  ]"
                  @click="clickPop(item.smallLotsQuantity)"
                  @change="changeSmallLotsQuantity(item, index)"
                  :error-messages="item.smallQtErrorMsg"
                  :ref="'smallLotsQtyCheck' + index"
                />
              </div>
            </template>

            <template v-slot:[`item.transportRequirementEdit`]="{ item, index }">
              <!-- 輸送要件編集ボタン-->
              <template>
                <v-btn small @click="openTransportRequirement(item, index)" text class="pen-icon">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </template>
            <template v-slot:[`item.transportRequirement`]="{ item }">
              <!-- 輸送要件-->
              <v-container class="d-flex opt-hist-list-container">
                <template v-for="(transportReqItem, index) in transportRequirementList">
                  <!-- 選択した輸送要件を表示 -->
                  <div
                    class="flex-item"
                    v-if="item.transportRequirement.includes(transportReqItem.value)"
                    :key="index"
                    style="white-space: nowrap"
                  >
                    {{ transportReqItem.text }} /&nbsp;
                  </div>
                </template>
              </v-container>
              <div
                v-if="item.transportReqErrorMsg"
                class="error-message"
                style="white-space: nowrap"
              >
                {{ item.transportReqErrorMsg }}
              </div>
            </template>

            <template v-slot:[`item.remarks`]="{ item }">
              <!-- 備考-->
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.remarks"
                maxlength="200"
                clear-icon="mdi-close-circle"
                clearable
                style="width: 200px"
                :error-messages="item.remarksErrorMsg"
              />
            </template>

            <template v-slot:[`item.deleteData`]="{ item }">
              <!-- 削除-->
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :trnListFlg="infoDialog.trnListFlg"
        :homePageFlag="infoDialog.homePageFlag"
        :trnAddFlg="infoDialog.trnAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 輸送要件選択ダイアログ-->
      <v-dialog
        v-model="dialogTransportRequirementsBreakdown"
        :max-width="800"
        persistent=""
        no-click-animation
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_transportRequirementSelect") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- 輸送要件選択テーブルデータ -->
              <v-data-table
                id="workInstructionsData"
                fixed-header
                :headers="transportRequirementsHeader"
                :items="transportRequirementList"
                disable-sort
                :hide-default-footer="true"
                :items-per-page="10"
              >
                <!-- 一覧チェックボックス -->
                <template v-slot:[`item.check`]="{ item }">
                  <input
                    type="checkbox"
                    v-model="item.check"
                    :value="listCheckbox"
                    style="transform: scale(2)"
                    :disabled="item.disabledFlg"
                  />
                </template>
                <!-- 輸送要件 -->
                <template v-slot:[`item.transportRequirement`]="{ item }">
                  <span>
                    <!-- 輸送要件を表示 -->
                    {{ item.text }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!-- 閉じるボタン領域 -->
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" @click="closeDialogTransportRequirements">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!-- 設定ボタン -->
                <v-btn class="other-btn" @click="setTransportRequirements">
                  {{ $t("btn.btn_config") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_TOD_ADD,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CheckDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    supplierHint: "",
    // 発注日
    orderDate: "",
    orderDateCal: "",
    orderDateMenu: false,
    // 輸送担当営業所
    officeSelected: "",
    officeList: [],
    productHintOffice: "",
    // 業務No
    bussinessNoSelected: "",
    bussinessNoList: [],
    // 集荷元
    pickupPoint: "",
    pickupPointCd: "",
    pickupPointSid: "",
    pickupPointList: [],
    productHintPickup: "",
    // 集荷区分
    prevPickupDivSelected: "",
    pickupDivSelected: "",
    pickupDivList: [],
    // 集荷予定日
    pickupScheduleDate: "",
    pickupScheduleDateCal: "",
    pickupScheduleDateMenu: false,
    // 集荷予定時間FROM
    pickupScheduleTimeFrom: "",
    // 集荷予定時間TO
    pickupScheduleTimeTo: "",
    // 配達先
    deliveryPoint: "",
    deliveryPointCd: "",
    deliveryPointSid: "",
    deliveryPointList: [],
    productHintDelivery: "",
    // 配達区分
    deliveryDivSelected: "",
    deliveryDivList: [],
    // 配達予定日
    deliveryScheduleDate: "",
    deliveryScheduleDateCal: "",
    deliveryScheduleDateMenu: false,
    // 配達予定時間FROM
    deliveryScheduleTimeFrom: "",
    // 配達予定時間TO
    deliveryScheduleTimeTo: "",
    // お客様問合せ番号
    inquiryNumber: "",
    // 有償/無償
    isTarget: "0",
    targetDivList: [],
    // 非公開/公開
    isPrivate: "0",
    privateDivList: [],
    isDisabledPrivate: false,
    inputList: [],
    // 荷姿
    packingNacCdList: [],
    // 輸送要件
    transportRequirementList: [],
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    truckInfo: {
      suppliersSelected: "",
      officeSid: "",
      truckInfo: "",
    },
    // 輸送要件選択ダイアログ
    dialogTransportRequirementsBreakdown: false,
    transportRequirementsHeader: [
      { value: "check", align: "left", width: "10%" },
      {
        text: i18n.tc("label.lbl_transportRequirement"),
        value: "transportRequirement",
        align: "left",
        width: "90%",
      },
    ],
    // データテーブルallCheckFlg
    allWorkInstructionsCheckFlg: false,
    fixedOptHistOrder: [],
    // 作業指示内訳選択行インデックス
    clickRowIndex: 0,

    // 一覧選択チェックボックス
    listCheckbox: [],
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_packing"),
        value: "packing",
        width: "200px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_weight"),
        value: "weight",
        width: "200px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "freightSizeL",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_freightSize"),
        value: "freightSizeW",
        width: "80px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "freightSizeH",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_smallLotsQuantity"),
        value: "smallLotsQuantity",
        width: "150px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "transportRequirementEdit",
        width: "80px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_transportRequirementArrow"),
        value: "transportRequirement",
        width: "140px",
        align: "left",
      },
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        width: "230px",
        align: "center",
      },
      {
        // 削除
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "74px",
        align: "center",
      },
    ],
    // メニュー
    openMenu: null,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    defaultItem: {
      No: "",
      productName: "",
      packing: "",
      packingList: [],
      weight: "",
      productHint: "",
      freightSizeL: "",
      freightSizeW: "",
      freightSizeH: "",
      smallLotsQuantity: "",
      transportRequirement: [],
      transportReqMenu: false,
    },
    getOfficeSelected: "",
    supplierInput: "",
    pickupInput: "",
    deliveryInput: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    updateDialogMessage: false,
    backMessage: "",
    pickupPointAlertMessage: "",
    deliveryPointAlertMessage: "",
    deliveryDivAlertMessage: "",
    pickupScheduleTimeAlertMessage: "",
    deliveryScheduleDateAlertMessage: "",
    deliveryScheduleTimeAlertMessage: "",
    // バリデーションルール
    rules: {
      inputNumber92: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");
            if (numL[0].length > 9 || numL[1].length > 2)
              return i18n.tc("check.chk_inputNumber_9-2");
          } else {
            if (ar.length > 9) {
              return i18n.tc("check.chk_inputNumber_9-2");
            }
          }
        }
        return true;
      },
      checkQuantity: function (value) {
        let isValid;
        if (value != null && value != undefined && value != "") {
          value = String(value).replaceAll(",", "");
          const regex = /^([1-9])(\d{0,4})$/;
          if (regex.test(Number(value))) {
            isValid = true;
          } else {
            isValid = false;
          }
          if (!isValid) {
            return i18n.tc("check.chk_inputNumber_5");
          }
        }
        return true;
      },
      inputNumberOverZero: (value, isWeight) => {
        if (value != null && value != undefined && value != "") {
          let numberVal = value;
          if (numberVal == 0) {
            if (isWeight) {
              return i18n.tc("check.chk_inputMoreOneDotZero");
            } else {
              return i18n.tc("check.chk_inputMoreOneDot");
            }
          }
        }
        return true;
      },
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /*
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.suppliersSelected) {
        // 連続登録の場合
        this.infoDialog.isOpen = false;
        this.inputList = [];
        // 連続登録フラグ
        this.continueAddFlg = true;
        // for (var i = 0; i < 10; i++) {
        //   this.defaultItem.No = i + 1;
        //   this.inputList.push(Object.assign({}, this.defaultItem));
        // }
        // バリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
        this.$refs.form.resetValidation();
        // マスタ取得
        this.getMstCode();
      } else {
        // 初期画面
        this.truckNm = "";
        this.suppliersSelected = "";
        this.orderDate = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.officeSelected = "";
        this.pickupPoint = "";
        this.pickupDivSelected = "03";
        this.pickupScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleTimeFrom = "";
        this.pickupScheduleTimeTo = "";
        this.deliveryPoint = "";
        this.deliveryDivSelected = "01";
        this.deliveryScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleTimeFrom = "";
        this.deliveryScheduleTimeTo = "";
        this.inquiryNumber = "";
        this.isTarget = "0";
        this.isPrivate = "0";
        this.packingNacCdList = [];
        // 連続登録フラグ
        this.continueAddFlg = true;
        // マスタ取得
        this.getMstCode();
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};
      // 取引先コード（前方一致）
      params.supplierCode = val;
      // 発注日(有効期限検索用)
      params.validDate = dateTimeHelper.convertUTC(this.orderDate);

      const clientList = getParameter.getTmsBizCustomerMst(params);
      // const clientList = [
      //   {
      //     value: "4400710",
      //     text: "4400710（ボルボ・カー・ジャパン㈱埼玉サービスセンター）",
      //     name: "ボルボ・カー・ジャパン㈱埼玉サービスセンター",
      //   },
      // ];
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // const pickingList = [
      //   {
      //     text: "自拠点出荷",
      //     value: "01",
      //   },
      //   {
      //     text: "荷主出荷（持込）",
      //     value: "02",
      //   },
      //   {
      //     text: "荷主出荷（集荷）",
      //     value: "03",
      //   },
      // ];
      // const deliveryList = [
      //   {
      //     text: "配達要",
      //     value: "01",
      //   },
      //   {
      //     text: "営業止め",
      //     value: "02",
      //   },
      // ];
      // const packingCodeList = [
      //   {
      //     text: "洋樽",
      //     value: "BA",
      //   },
      //   {
      //     text: "束",
      //     value: "BE",
      //   },
      //   {
      //     text: "袋",
      //     value: "BG",
      //   },
      // ];
      // const targetList = [
      //   {
      //     text: "対象",
      //     value: "0",
      //   },
      //   {
      //     text: "対象外",
      //     value: "1",
      //   },
      // ];
      // const privateList = [
      //   {
      //     text: "非公開",
      //     value: "0",
      //   },
      //   {
      //     text: "公開",
      //     value: "1",
      //   },
      // ];
      // const transportList = [
      //   {
      //     text: "われもの注意",
      //     value: "01",
      //   },
      //   {
      //     text: "取扱注意",
      //     value: "02",
      //   },
      //   {
      //     text: "水濡注意",
      //     value: "03",
      //   },
      // ];
      // 集荷方法区分
      const pickingList = getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.PICKUP_DIV });
      // 配達方法区分
      const deliveryList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.DELIVERY_DIV,
      });
      // 荷姿(NACCSコード)
      const packingCodeList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.NACCS_CODE,
      });
      // 配車計画対象フラグ
      const targetList = getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.IS_TARGET_FLG });
      // 公開フラグ
      const privateList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.IS_PRIVATE_FLG,
      });
      // 輸送要件
      const transportList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.TRANSPORT_REQUIREMENT_DIV,
      });
      Promise.all([
        pickingList,
        deliveryList,
        packingCodeList,
        targetList,
        privateList,
        transportList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.pickupDivList = result[0];
          this.deliveryDivList = result[1];
          this.packingNacCdList = result[2];
          this.targetDivList = result[3];
          this.privateDivList = result[4];
          this.transportRequirementList = result[5];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          for (var j = 0; j < 10; j++) {
            this.defaultItem.No = j + 1;
            this.defaultItem.packingList = this.packingNacCdList;
            this.inputList.push(Object.assign({}, this.defaultItem));
          }
        });
    },
    changeIsTarget(newVal) {
      if (newVal == "1") {
        this.isDisabledPrivate = true;
      } else {
        this.isDisabledPrivate = false;
      }
    },
    /**
     * 受注登録画面：入力カンマ処理 重さ
     */
    changeWeight(val, index) {
      this.inputList[index].weight = commonFunction.getDelimiterFew(val.weight, 9, 3);
    },
    /**
     * 受注登録画面：重さ ヒント表示
     */
    setHintWeight(value, index) {
      return this.inputList[index].weight;
    },
    /**
     * 受注登録画面：入力カンマ処理 サイズ
     */
    changeSize(val, index, param) {
      switch (param) {
        case "L":
          this.inputList[index].freightSizeL = commonFunction.getDelimiterFew(
            val.freightSizeL,
            9,
            2
          );
          break;
        case "W":
          this.inputList[index].freightSizeW = commonFunction.getDelimiterFew(
            val.freightSizeW,
            9,
            2
          );
          break;
        case "H":
          this.inputList[index].freightSizeH = commonFunction.getDelimiterFew(
            val.freightSizeH,
            9,
            2
          );
          break;
      }
    },
    /**
     * 受注登録画面：サイズ ヒント表示
     */
    setHintSize(value, index, param) {
      switch (param) {
        case "L":
          return this.inputList[index].freightSizeL;
        case "W":
          return this.inputList[index].freightSizeW;
        case "H":
          return this.inputList[index].freightSizeH;
      }
    },
    /**
     * 受注登録画面：入力カンマ処理 小口数
     */
    changeSmallLotsQuantity(val, index) {
      this.inputList[index].smallLotsQuantity = commonFunction.getDelimiter(
        val.smallLotsQuantity,
        5
      );
    },
    //カンマ削除処理
    clickPop(val) {
      let myName = event.target;
      let pop = val ?? "";
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 受注登録画面：日付を－１日
     */
    prevDate(param) {
      switch (param) {
        case "order":
          this.orderDateCal = commonFunction.addDate(this.orderDateCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateCal = commonFunction.addDate(this.pickupScheduleDateCal, "");
          break;
        case "delivery":
          this.deliveryScheduleDateCal = commonFunction.addDate(this.deliveryScheduleDateCal, "");
          break;
      }
    },

    /**
     * 受注登録画面：日付を＋１日
     */
    nextDate(param) {
      switch (param) {
        case "order":
          this.orderDateCal = commonFunction.addDate(this.orderDateCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateCal = commonFunction.addDate(this.pickupScheduleDateCal, "add");
          break;
        case "delivery":
          this.deliveryScheduleDateCal = commonFunction.addDate(
            this.deliveryScheduleDateCal,
            "add"
          );
          break;
      }
    },

    /**
     * 受注登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 受注登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }

      this.$refs["weightCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeLCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeWCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeHCheck" + this.editedIndex].resetValidation();
      this.$refs["smallLotsQtyCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 受注登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },
    /**
     * 受注登録画面：輸送担当営業所コードリストボックス取得処理
     */
    setSrhOffice() {
      return commonFunction.getListSetName(this.officeList, this.officeSelected);
    },
    /**
     * 受注登録画面：集荷元コードリストボックス取得処理
     */
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPoint);
    },
    /**
     * 受注登録画面：配達先コードリストボックス取得処理
     */
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPoint);
    },

    /**
     * 受注登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 受注登録画面：日付入力処理
     */
    changeDate(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateCal = formatedDt;
          break;
      }
    },

    /**
     * 受注登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 受注登録画面：登録ボタン押下
     */
    trnAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].listItemsErrorMsg = i18n.tc("");
        this.inputList[i].smallQtErrorMsg = i18n.tc("");
        this.inputList[i].transportReqErrorMsg = i18n.tc("");
        this.inputList[i].remarksErrorMsg = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let cnt = 0;
      let countCheckFlg = true;
      this.pickupPointAlertMessage = "";
      this.deliveryPointAlertMessage = "";
      this.deliveryDivAlertMessage = "";
      this.pickupScheduleTimeAlertMessage = "";
      this.deliveryScheduleDateAlertMessage = "";
      this.deliveryScheduleTimeAlertMessage = "";
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productName) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productName);
          } else {
            // 上記以外の場合は処理なし。
          }
        }
        if (
          this.pickupPointCd == this.deliveryPointCd &&
          this.pickupPointSid == this.deliveryPointSid
        ) {
          this.deliveryPointAlertMessage = i18n.tc("check.chk_pickupDeliveryPointVali");
          return;
        }
        // 配達方法区分が自拠点出荷または荷主出荷（持込）の場合
        if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
          if (this.deliveryDivSelected == "02") {
            // 配達方法区分が営業所止めの場合はエラー
            this.deliveryDivAlertMessage = i18n.tc("check.chk_pickupDeliveryDivVali");
            return;
          }
        }
        // 集荷方法区分が自拠点出荷または荷主出荷（持込）の場合
        if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
          if (this.officeSelected != this.pickupPoint) {
            // 輸送担当営業所と集荷元が同じでない場合はエラー
            this.pickupPointAlertMessage = i18n.tc("check.chk_pickupDeliveryDivPointVali");
            return;
          }
        }
        // 配達方法区分が配達要の場合
        if (this.deliveryDivSelected == "01") {
          if (this.officeSelected == this.deliveryPoint) {
            // 輸送担当拠点と配達先が同じ場合はエラー
            this.deliveryPointAlertMessage = i18n.tc("check.chk_officeDeliveryDivPointVali");
            return;
          }
        }
        // 配達方法区分が営業所止めの場合
        if (this.deliveryDivSelected == "02") {
          if (this.officeSelected != this.deliveryPoint) {
            // 輸送担当営業所と配達先が同じでない場合はエラー
            this.deliveryPointAlertMessage = i18n.tc("check.chk_deliveryDivPointVali");
            return;
          }
        }
        if (
          // 集荷予定日、配達予定日の日付、相関チェック
          new Date(this.pickupScheduleDate).getTime() >
          new Date(this.deliveryScheduleDate).getTime()
        ) {
          this.deliveryScheduleDateAlertMessage = i18n.tc("check.chk_inputDeliveryScheduleVali");
          return;
        } else {
          // 処理なし
        }
        const wd = "2000/01/01";
        const startDate = new Date(`${wd} ${this.pickupScheduleTimeFrom}`);
        const endDate = new Date(`${wd} ${this.pickupScheduleTimeTo}`);
        if (
          // 集荷予定時間のFromTo、入力チェック
          this.pickupScheduleTimeFrom &&
          this.pickupScheduleTimeTo &&
          startDate > endDate
        ) {
          this.pickupScheduleTimeAlertMessage = i18n.tc("check.chk_inputFromDate");
          return;
        } else {
          // 処理なし
        }
        const startDate2 = new Date(`${wd} ${this.deliveryScheduleTimeFrom}`);
        const endDate2 = new Date(`${wd} ${this.deliveryScheduleTimeTo}`);
        if (
          // 配達予定時間のFromTo、入力チェック
          this.deliveryScheduleTimeFrom &&
          this.deliveryScheduleTimeTo &&
          startDate2 > endDate2
        ) {
          this.deliveryScheduleTimeAlertMessage = i18n.tc("check.chk_inputFromDate");
          return;
        } else {
          // 処理なし
        }

        var errNo = false;
        var duplicateFlg = false;
        for (let i = 0; i < this.inputList.length; i++) {
          let count = 0;
          // 品番の入力有無チェック
          if (this.inputList[i].productName != null && this.inputList[i].productName != "") {
            count++;
          }
          // 出荷予定数の入力有無チェック
          if (this.inputList[i].lbl_packing != null && this.inputList[i].lbl_packing != "") {
            count++;
          }
          // ロットの入力有無チェック
          if (this.inputList[i].weight != null && this.inputList[i].weight != "") {
            count++;
          }
          // サイズ（長さ）の入力有無チェック
          if (this.inputList[i].freightSizeL != null && this.inputList[i].freightSizeL != "") {
            count++;
          }
          // サイズ（幅）の入力有無チェック
          if (this.inputList[i].freightSizeW != null && this.inputList[i].freightSizeW != "") {
            count++;
          }
          // サイズ（高さ）の入力有無チェック
          if (this.inputList[i].freightSizeH != null && this.inputList[i].freightSizeH != "") {
            count++;
          }
          // 小口数の入力有無チェック
          if (
            this.inputList[i].smallLotsQuantity != null &&
            this.inputList[i].smallLotsQuantity != ""
          ) {
            count++;
          }
          // 輸送要件の入力有無チェック
          if (
            this.inputList[i].transportRequirement != null &&
            this.inputList[i].transportRequirement != ""
          ) {
            count++;
          }
          // 備考の入力有無チェック
          if (this.inputList[i].remarks != null && this.inputList[i].remarks != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.inputList[i].productName == null ||
              this.inputList[i].productName == "" ||
              this.inputList[i].packing == null ||
              this.inputList[i].packing == "" ||
              this.inputList[i].weight == null ||
              this.inputList[i].weight == "" ||
              this.inputList[i].freightSizeL == null ||
              this.inputList[i].freightSizeL == "" ||
              this.inputList[i].freightSizeW == null ||
              this.inputList[i].freightSizeW == "" ||
              this.inputList[i].freightSizeH == null ||
              this.inputList[i].freightSizeH == "" ||
              this.inputList[i].smallLotsQuantity == null ||
              this.inputList[i].smallLotsQuantity == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.inputList[i].listItemsErrorMsg = i18n.tc("check.chk_input");
              this.inputList[i].smallQtErrorMsg = i18n.tc("check.chk_input");
            } else {
              // 必須項目の未入力がない場合
              // 小口数以外の項目がすべて重複している場合、エラー
              let targets = [];
              targets = this.inputList.filter((element) => {
                return (
                  element.No !== this.inputList[i].No &&
                  element.productName === this.inputList[i].productName &&
                  element.packing === this.inputList[i].packing &&
                  element.weight === this.inputList[i].weight &&
                  element.freightSizeL === this.inputList[i].freightSizeL &&
                  element.freightSizeW === this.inputList[i].freightSizeW &&
                  element.freightSizeH === this.inputList[i].freightSizeH &&
                  JSON.stringify(element.transportRequirement) ===
                    JSON.stringify(this.inputList[i].transportRequirement) &&
                  element.remarks === this.inputList[i].remarks
                );
              });

              // 重複があった場合
              if (targets.length > 0) {
                duplicateFlg = true;
                for (const element of targets) {
                  // 小口数以外に項目重複のエラー表示
                  element.listItemsErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                  element.transportReqErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                  element.remarksErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                }
              }
            }
          }
        }

        if (errNo || duplicateFlg) {
          return;
        }
        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-TOD-001_001_E");
          cnt = i + 1;
          countCheckFlg = true;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productName) {
            var checkWeightNum = Number(
              commonUtil.zen2han(this.inputList[i].weight).replaceAll(",", "")
            );
            var checkSizeNumL = Number(
              commonUtil.zen2han(this.inputList[i].freightSizeL).replaceAll(",", "")
            );
            var checkSizeNumW = Number(
              commonUtil.zen2han(this.inputList[i].freightSizeW).replaceAll(",", "")
            );
            var checkSizeNumH = Number(
              commonUtil.zen2han(this.inputList[i].freightSizeH).replaceAll(",", "")
            );
            var checkLotsQtyNum = Number(
              commonUtil.zen2han(this.inputList[i].smallLotsQuantity).replaceAll(",", "")
            );

            // 数値チェック
            if (
              isNaN(checkWeightNum) ||
              isNaN(checkSizeNumL) ||
              isNaN(checkSizeNumW) ||
              isNaN(checkSizeNumH) ||
              isNaN(checkLotsQtyNum)
            ) {
              // 出荷予定数、ボール数、ケース数、バラ数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (Number.isInteger(checkLotsQtyNum)) {
              // 処理なし
            } else {
              // 出荷予定数、ボール数、ケース数、バラ数が整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (
              0 < checkWeightNum &&
              0 < checkSizeNumL &&
              0 < checkSizeNumW &&
              0 < checkSizeNumH &&
              0 < checkLotsQtyNum
            ) {
              // 処理なし
            } else {
              // 出荷予定数、ボール数、ケース数、バラ数が0未満の場合
              cnt = i + 1;
            }
          }
        }
        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          this.ConfirmDialog.screenFlag = false;
          this.ConfirmDialog.changeFlag = true;
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.inputList.length; j++) {
              let checkPosition = String(j);
              if (!this.inputList[j].productCd) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.weightCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeLCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeWCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeHCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.smallLotsQtyCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 受注登録画面：出荷予定API（POST）
     */
    addTransport() {
      // メッセージ初期化
      this.pickupPointAlertMessage = "";
      this.deliveryPointAlertMessage = "";
      this.deliveryDivAlertMessage = "";
      this.pickupScheduleTimeAlertMessage = "";
      this.deliveryScheduleDateAlertMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_001;

      // 受注List
      let bodyList = [];

      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].productName != "" && this.inputList[i].productName != null) {
          let inputMap = {};
          // 品名
          inputMap.itemName = this.inputList[i].productName;
          // 荷姿
          for (let m = 0; m < this.packingNacCdList.length; m++) {
            if (this.packingNacCdList[m].value == this.inputList[i].packing) {
              inputMap.packingNacCd = this.packingNacCdList[m].value;
            }
          }
          // 重量
          inputMap.totalWeight = this.inputList[i].weight.replaceAll(",", "");
          // サイズ
          inputMap.freightSizeL = this.inputList[i].freightSizeL.replaceAll(",", "");
          inputMap.freightSizeW = this.inputList[i].freightSizeW.replaceAll(",", "");
          inputMap.freightSizeH = this.inputList[i].freightSizeH.replaceAll(",", "");
          // 小口数
          inputMap.numOfSmallLot = this.inputList[i].smallLotsQuantity.replaceAll(",", "");
          // 輸送要件
          const transportRequirements = [];
          if (this.inputList[i].transportRequirement.length > 0) {
            for (const item of this.inputList[i].transportRequirement) {
              for (let m = 0; m < this.transportRequirementList.length; m++) {
                if (this.transportRequirementList[m].value == item) {
                  transportRequirements.push({
                    transportRequirement: this.transportRequirementList[m].value,
                  });
                }
              }
            }
          }
          inputMap.transportRequirements = transportRequirements;
          // 備考
          if (!this.inputList[i].remarks) {
            inputMap.remarks = "";
          } else {
            inputMap.remarks = this.inputList[i].remarks;
          }

          bodyList.push(inputMap);
        }
      }

      // 取引先
      body.reqIdv.clientCd = this.suppliersSelected;
      // 発注日
      body.reqIdv.orderDate = dateTimeHelper.convertUTC(this.orderDate);
      // 輸送担当営業所
      body.reqIdv.officeSid = this.officeSelected;
      // 請求No
      body.reqIdv.businessNo = this.bussinessNoSelected;
      // 集荷元
      body.reqIdv.pickupPointCd = this.pickupPointCd;
      body.reqIdv.pickupPointSid = this.pickupPointSid;
      // 集荷区分
      body.reqIdv.pickupWayDiv = this.pickupDivSelected;
      // 集荷予定日
      body.reqIdv.pickupScheduleDate = dateTimeHelper.convertUTC(this.pickupScheduleDate);
      // 集荷予定時間From
      if (!this.pickupScheduleTimeFrom) {
        body.reqIdv.pickupScheduleFromDate = "";
      } else {
        const pickupSchduleFromDate = this.pickupScheduleDate + " " + this.pickupScheduleTimeFrom;
        body.reqIdv.pickupScheduleFromDate = dateTimeHelper.convertUTC(pickupSchduleFromDate);
      }
      // 集荷予定時間To
      if (!this.pickupScheduleTimeTo) {
        body.reqIdv.pickupSchedulToDate = "";
      } else {
        const pickupSchduleToDate = this.pickupScheduleDate + " " + this.pickupScheduleTimeTo;
        body.reqIdv.pickupScheduleToDate = dateTimeHelper.convertUTC(pickupSchduleToDate);
      }
      body.reqIdv.pickupSchedulToDate = this.pickupScheduleTimeTo;
      // 配達先
      body.reqIdv.deliveryPointCd = this.deliveryPointCd;
      body.reqIdv.deliveryPointSid = this.deliveryPointSid;
      // 配達区分
      body.reqIdv.deliveryWayDiv = this.deliveryDivSelected;
      // 配達予定日
      body.reqIdv.deliveryScheduleDate = dateTimeHelper.convertUTC(this.deliveryScheduleDate);
      // 配達予定時間From
      if (!this.deliveryScheduleTimeFrom) {
        body.reqIdv.deliveryScheduleFromDate = "";
      } else {
        const deliverySchduleFromDate =
          this.deliveryScheduleDate + " " + this.deliveryScheduleTimeFrom;
        body.reqIdv.deliveryScheduleFromDate = dateTimeHelper.convertUTC(deliverySchduleFromDate);
      }
      // 配達予定時間To
      if (!this.deliveryScheduleTimeTo) {
        body.reqIdv.deliveryScheduleToDate = "";
      } else {
        const deliveryScheduleToDate =
          this.deliveryScheduleDate + " " + this.deliveryScheduleTimeTo;
        body.reqIdv.deliveryScheduleToDate = dateTimeHelper.convertUTC(deliveryScheduleToDate);
      }
      // お客様問合せ番号
      body.reqIdv.inquiryNumber = this.inquiryNumber;
      // 対象/対象外
      body.reqIdv.vehiclePlanFlg = this.isTarget;
      // 非公開/公開
      body.reqIdv.privateFlg = this.isPrivate;
      // 受注List
      body.reqIdv.packingDetails = bodyList;

      // ↓↓-------仮置きで正常時の結果画面を表示 共通処理完成次第、ここは削除=-------↓↓
      // this.infoDialog.message =
      //   "送り状番号：XXXXXXXXXXXXXXXXXXXXX で" + messsageUtil.getMessage("P-RCV-001_001_C");
      // this.infoDialog.title = "結果";
      // this.infoDialog.isOpen = true;
      // this.infoDialog.outsideClickNotCloseFlg = true;
      // // 画面遷移ボタン
      // this.infoDialog.homePageFlag = true;
      // this.infoDialog.trnListFlg = true;
      // this.infoDialog.trnAddFlg = true;
      // this.infoDialog.firstPageFlag = false;
      // // ローディング画面表示OFF
      // this.loadingCounter = 0;
      // ↑↑---------------------------------------------------------↑↑

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_TRANSPORT_INSERT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addTransport() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message =
                "送り状番号：" +
                jsonData.resIdv.invNumSid +
                " で" +
                messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.trnListFlg = true;
              this.infoDialog.trnAddFlg = true;
              this.infoDialog.InvResTransitionFlg =
                sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_STK_INVEN_ALLOC_RESERVE) ==
                "true"
                  ? true
                  : false;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.trnListFlg = false;
        this.infoDialog.trnAddFlg = false;
        this.infoDialog.InvResTransitionFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 連続登録の場合
      if (this.continueAddFlg) {
        this.suppliersSelected = "";
        // 連続登録フラグ
        this.continueAddFlg = false;
      }
      // 表示されている行を全て削除する
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList.splice(i);
      }
      // 変更検知用の設定
      this.orderDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.$refs.editedList.resetValidation();
    },

    /**
     *輸送要件選択ダイアログを表示
     */
    openTransportRequirement(item, index) {
      // 輸送要件選択ダイアログのデータ
      for (var i = 0; i < this.transportRequirementList.length; i++) {
        // 選択されている輸送要件のみチェックをつける
        this.transportRequirementList[i].check = false;
        if (item.transportRequirement.includes(this.transportRequirementList[i].value)) {
          this.transportRequirementList[i].check = true;
        }
      }
      //輸送要件選択ボタン選択行インデックス
      this.clickRowIndex = index;
      this.dialogTransportRequirementsBreakdown = true;
    },
    /**
     * 輸送要件選択ダイアログ：閉じる処理
     */
    closeDialogTransportRequirements() {
      this.dialogTransportRequirementsBreakdown = false;
    },
    /**
     * 輸送要件選択ダイアログ：設定処理
     */
    setTransportRequirements() {
      //輸送要件選択ダイアログでチェックが入った行の指示内容を設定
      this.inputList[this.clickRowIndex].transportRequirement = this.transportRequirementList
        .filter((val) => val.check == true)
        .map((val) => {
          return val.value;
        });

      this.dialogTransportRequirementsBreakdown = false;
    },
    changeSupplier(value) {
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.supplierHint = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.supplierHint = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 輸送担当営業所 プルダウンチェンジイベント
     */
    changeOffice(value) {
      // 輸送担当営業所を選択時
      let val = this.officeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintOffice = val.text;
        this.getOfficeSelected = val.text;
      }
      this.officeList = this.officeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintOffice = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 集荷先 プルダウンチェンジイベント
     */
    changePickup(value) {
      this.pickupPointAlertMessage = "";
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.pickupPointCd = val.customerCode;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointCd = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 集荷方法区分フォーカスイベント
     * 変更前の値を取得しておく
     */
    focusPickupDiv() {
      this.prevPickupDivSelected = this.pickupDivSelected;
    },
    /**
     * 集荷方法区分 プルダウンチェンジイベント
     */
    changePickupDiv(value) {
      if (value == "03") {
        // 荷主出荷（集荷）に変更されたら、集荷元をクリアする
        this.pickupPoint = "";
        this.changePickup("");
        this.pickupPointAlertMessage = "";
      } else if (this.prevPickupDivSelected == "03") {
        // 荷主出荷（集荷）から他の値に変更されたら、集荷元をクリアする
        this.pickupPoint = "";
        this.changePickup("");
        this.pickupPointAlertMessage = "";
      }
    },
    /**
     * 配達方法区分 プルダウンチェンジイベント
     */
    changeDeliveryDiv(value) {
      if (value == "02") {
        if (this.deliveryPoint) {
          // 営業所止めで配達先が選択されている場合、APIを呼び出して取得しなおす
          this.getDeliverySelected(this.deliveryPoint, "sid");
        }
      }
    },
    /**
     * 配達先 プルダウンチェンジイベント
     */
    changeDelivery(value) {
      this.deliveryPointAlertMessage = "";
      // 集荷先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.deliveryPointCd = val.customerCode;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointCd = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 出荷予定数0ダイアログ、OK
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    getPickupSelected(val, param) {
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // 選択した会社コードを取得
      // let supplierCd = this.supplierList.find(
      //   (element) => element.value === this.suppliersSelected
      // ).value;
      const params = {};
      // 取引先コード
      // params.customerCode = supplierCd;
      // 拠点管理種別（集荷方法区分：自拠点出荷・荷主出荷（持込）の場合、2=営業所を取得）
      if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
        params.baseOwnerType = "2";
      } else {
        // そのほかの場合、1=拠点を取得
        params.baseOwnerType = "1";
      }
      // params.baseOwnerType = "1";
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      if (param === "sid") {
        // 拠点Sid
        params.baseSid = val;
      } else {
        // 拠点コード
        params.baseCode = val;
      }
      // 集荷予定日（有効期限検索用）
      params.validDate = dateTimeHelper.convertUTC(this.pickupScheduleDate);

      const pickupListTmp = getParameter.getTmsBizBaseMst(params);
      // const pickupListTmp = [
      //   {
      //     text: "002（VCJ埼玉SC）",
      //     value: "2400000002",
      //     code: "4400710",
      //     name: "VCJ埼玉SC",
      //   },
      //   {
      //     text: "003（VC東名横浜）",
      //     value: "2400000003",
      //     code: "4400710",
      //     name: "VC東名横浜",
      //   },
      //   {
      //     text: "004（VC港北NT）",
      //     value: "2400000004",
      //     code: "4400710",
      //     name: "VC港北NT",
      //   },
      //   {
      //     text: "005（VC目黒）",
      //     value: "2400000005",
      //     code: "4400710",
      //     name: "VC目黒",
      //   },
      //   {
      //     text: "006（VC練馬）",
      //     value: "2400000006",
      //     code: "4400710",
      //     name: "VC練馬",
      //   },
      //   {
      //     text: "007（VC杉並）",
      //     value: "2400000007",
      //     code: "4400710",
      //     name: "VC杉並",
      //   },
      //   {
      //     text: "008（VC世田谷）",
      //     value: "2400000008",
      //     code: "4400710",
      //     name: "VC世田谷",
      //   },
      // ];

      Promise.all([pickupListTmp])
        .then((result) => {
          // 画面の初期値を設定します。
          this.pickupPointList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getDeliverySelected(val, param) {
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // 選択した会社コードを取得
      // let supplierCd = this.supplierList.find(
      //   (element) => element.value === this.suppliersSelected
      // ).value;
      const params = {};
      // 取引先コード
      // params.customerCode = supplierCd;
      // 拠点管理種別（配達方法区分：営業所止めの場合、2=営業所を取得）
      if (this.deliveryDivSelected == "02") {
        params.baseOwnerType = "2";
      }
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      if (param === "sid") {
        // 拠点Sid
        params.baseSid = val;
      } else {
        // 拠点コード
        params.baseCode = val;
      }
      // 配達予定日（有効期限検索用）
      params.validDate = dateTimeHelper.convertUTC(this.deliveryScheduleDate);

      const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
      // const deliveryListTmp = [
      //   {
      //     text: "002（VCJ埼玉SC）",
      //     value: "2400000002",
      //     code: "4400710",
      //     name: "VCJ埼玉SC",
      //   },
      //   {
      //     text: "003（VC東名横浜）",
      //     value: "2400000003",
      //     code: "4400710",
      //     name: "VC東名横浜",
      //   },
      //   {
      //     text: "004（VC港北NT）",
      //     value: "2400000004",
      //     code: "4400710",
      //     name: "VC港北NT",
      //   },
      //   {
      //     text: "005（VC目黒）",
      //     value: "2400000005",
      //     code: "4400710",
      //     name: "VC目黒",
      //   },
      //   {
      //     text: "006（VC練馬）",
      //     value: "2400000006",
      //     code: "4400710",
      //     name: "VC練馬",
      //   },
      //   {
      //     text: "007（VC杉並）",
      //     value: "2400000007",
      //     code: "4400710",
      //     name: "VC杉並",
      //   },
      //   {
      //     text: "008（VC世田谷）",
      //     value: "2400000008",
      //     code: "4400710",
      //     name: "VC世田谷",
      //   },
      // ];

      Promise.all([deliveryListTmp])
        .then((result) => {
          // 画面の初期値を設定します。
          this.deliveryPointList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    getBussinessNoList() {
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};
      // 事業部・営業所コード
      params.officeCode = sessionStorage.getItem("office_cd");
      // 取引先コード
      params.requesterCustomerCode = this.suppliersSelected;
      // 業務No
      //params.businessNo = this.bussinessNoSelected;

      // 業務マスタ取得
      const bussinessNoList = getParameter.getOperationMst(params);
      //const bussinessNoList = [
      //  {
      //    value: "440710",
      //    name: "ＶＣＪ四輪輸送",
      //    text: "440710（ＶＣＪ四輪輸送）",
      //  },
      //  {
      //    value: "440711",
      //    name: "ＶＣＪ有料道路代",
      //    text: "440711（ＶＣＪ四輪輸送有料道路代）",
      //  },
      //  {
      //    value: "440712",
      //    name: "ＶＣＪチャーター",
      //    text: "440712（ＶＣＪチャーター）",
      //  },
      //  {
      //    value: "440713",
      //    name: "ＶＣＪ鉄板費用",
      //    text: "440713（ＶＣＪ鉄板費用）",
      //  },
      //];

      Promise.all([bussinessNoList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.bussinessNoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 請求No(業務No)ヒント取得処理
    setBussinessNo() {
      for (let i = 0; i < this.bussinessNoList.length; i++) {
        if (this.bussinessNoList[i].value == this.bussinessNoSelected) {
          return this.bussinessNoList[i].name;
        }
      }
    },
  },
  computed: {},
  watch: {
    orderDateCal: {
      handler(val) {
        this.orderDate = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateCal: {
      handler(val) {
        this.pickupScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateCal: {
      handler(val) {
        this.deliveryScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    orderDate: {
      handler() {
        // 発注日のチェック
        if (dateTimeHelper.isDisplayDateFormat(this.orderDate) && this.suppliersSelected) {
          // 発注日が正しい日付かつ、取引先選択済みの場合、取引先リストを取得
          this.getCustomInfo(this.suppliersSelected);
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    pickupScheduleDate: {
      handler() {
        // 集荷予定日のチェック
        if (dateTimeHelper.isDisplayDateFormat(this.pickupScheduleDate) && this.pickupPoint) {
          // 集荷予定日が正しい日付かつ、集荷元選択済みの場合、集荷元リストを再取得する
          this.getPickupSelected(this.pickupPoint, "sid");
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    deliveryScheduleDate: {
      handler() {
        // 配達予定日のチェック
        if (dateTimeHelper.isDisplayDateFormat(this.deliveryScheduleDate) && this.deliveryPoint) {
          // 配達予定日が正しい日付かつ、配達先選択済みの場合、配達先を取得する
          this.getDeliverySelected(this.deliveryPoint, "sid");
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    /**
     * 輸送担当営業所取得
     */
    getOfficeSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.officeList = [];
        //   this.officeSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（2=営業所を取得）
          params.baseOwnerType = "2";
          // 有効期限区分（0=有効期限内データを取得）
          params.validDateKbn = "0";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          const officeListTmp = getParameter.getTmsBizBaseMst(params);
          //const officeListTmp = [
          //  {
          //    text: "001（日高営業所）",
          //    value: "2400000001",
          //    displayText: "日高営業所",
          //    name: "日高営業所",
          //  },
          //];

          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.officeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },
    /**
     * 集荷元取得
     */
    pickupInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.pickupPointList = [];
        //   this.pickupPoint = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 集荷元リスト取得
          this.getPickupSelected(val);
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    /**
     * 配達先取得
     */
    deliveryInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPoint = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          this.getDeliverySelected(val);
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    /**
     * 取引先変更時
     */
    suppliersSelected() {
      // 請求Noクリア、再取得
      this.bussinessNoSelected = "";
      this.bussinessNoList = [];
      this.getBussinessNoList();
    },
    supplierInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.supplierHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
    supplierList() {
      // 取引選択リストが空になった場合、業務Noリストクリア
      if (this.supplierList.length === 0) {
        this.bussinessNoSelected = "";
        this.bussinessNoList = [];
        this.suppliersSelected = "";
      } else {
        // 空でない場合、業務Noリスト再取得
        this.getBussinessNoList();
      }
    },
    pickupPointList() {
      // 集荷元リストが空になった場合、前選択した値をクリア
      if (this.pickupPointList.length === 0) {
        this.pickupPoint = "";
      }
    },
    deliveryPointList() {
      // 配達先リストが空になった場合、前選択した値をクリア
      if (this.deliveryPointList.length === 0) {
        this.deliveryPoint = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//備考欄、テキストボックス
.search-textbox-remark {
  width: 44.7rem;
}
//輸送会社選択：テキストボックス
.serch-textbox-tr {
  width: 16rem;
}

#table-guide {
  padding: 0.2rem 0 0.1rem 0.2rem;
  display: inline-block;
  width: 13rem;
  text-align: left;
  border: solid 1px;
  margin: 0.2rem 0.5rem;
}
.item-spacer-sum {
  margin-left: 5.6rem;
  margin-right: 6px;
}

#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }
      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

// サイズの入力部分と×の間を狭める
#listData ::v-deep th:nth-child(5),
#listData ::v-deep td:nth-child(5) {
  padding-right: 0px;
}
#listData ::v-deep th:nth-child(6),
#listData ::v-deep td:nth-child(6) {
  padding: 0px;
}
#listData ::v-deep th:nth-child(7),
#listData ::v-deep td:nth-child(7) {
  padding-left: 0px;
}

.lbl-screen-name {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: #000;
  margin-left: -15px;
}

.add-textbox-remark {
  width: 68rem;
}

#dialogDelivery .v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  margin-bottom: 0px;
}

#dialogDelivery ::v-deep .error--text {
  margin-bottom: 5px;
}

.error-message {
  color: #ff5252;
  font-size: 12px;
}
</style>
