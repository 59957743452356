<template>
  <iframe
    frameborder="0"
    style="border: 0"
    referrerpolicy="no-referrer-when-downgrade"
    loading="lazy"
    :src="src"
    allowfullscreen
    :style="`width: ${width}; height: ${height}`"
  >
  </iframe>
</template>

<script>
export default {
  name: "GoogleEmbetMap",
  props: {
    mapMode: { type: String, required: true },
    params: { type: Object, required: true },
    width: { type: String, default: "100%" },
    height: { type: String, default: "100%" },
  },
  data() {
    return {
      url: "https://www.google.com/maps/embed/v1",
      apiKey: "AIzaSyCYXSNM04teYpRz2AyP6tMtrVvb6lqq1jQ",
    };
  },
  computed: {
    src() {
      const urlParams = new URLSearchParams();
      urlParams.set("key", this.apiKey);
      for (const [key, value] of Object.entries(this.params)) {
        if (!key || !value) {
          continue;
        }

        if (typeof value === "string") {
          urlParams.set(key, value);
        } else if (Array.isArray(value) && value.length > 0) {
          urlParams.set(key, value.join("|"));
        }
      }

      return `${this.url}/${this.mapMode}?${urlParams.toString()}`;
    },
  },
};
</script>
