<template>
  <div style="background: white">
    <!-- ローディング画面 -->
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_userList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row first-search-item">
            <!-- １段目-->
            <!-- 営業所 -->
            <OfficeSelecter
              v-model="officeSelected"
              :isRequired="true"
              :readOnry="true"
              @loading="loadingCounter = $event"
              class="search-autocomplete first-search-item"
            />
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザー名-->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="userName"
                :label="$t('label.lbl_userName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                maxlength="200"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 社員番号-->
            <div class="search-textbox" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="employeeCode"
                maxlength="20"
                :label="$t('label.lbl_employeeCode')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- HACモック対応 部品要求者所属 -->
            <c-belong-input
              v-model="commonForm.belongSelected"
              width="250px"
              v-if="partsRequesterAffiliationFlg"
            />
            <!-- 空白 -->
            <span v-if="partsRequesterAffiliationFlg" class="item-spacer">&nbsp;</span>
            <!-- HACモック対応 部品要求者所属 -->
            <!-- ユーザーサブ検索条件 -->
            <CodeSelecter
              v-model="userSubSearchConditionSelected"
              :code="userSubSearchConditionCode"
              :label="$t('label.lbl_subSearchCondition')"
              @loading="loadingCounter = $event"
              class="search-autocomplete"
            />
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" id="btn-search" class="get-btn" @click="search">{{
                  $t("btn.btn_search")
                }}</v-btn>
              </div>
              <div class="btn-search-area" style="float: right">
                <!--画面権限一括修正ボタン-->
                <v-btn color="primary" @click="moveRoleMasterBatchAddUpdate">{{
                  $t("label.lbl_roleMasterBatchAddUpdate")
                }}</v-btn>
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
                <!-- ログインQR発行ボタン-->
                <v-btn
                  ref="btnLoginQRIssue"
                  color="primary"
                  class="other-btn"
                  @click="loginQRIssue"
                  :disabled="userCheckList.length <= 0 && !isAllSelected"
                >
                  {{ $t("btn.btn_login_qr_issue") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="this.partsRequesterAffiliationFlg ? headerItems2 : headerItems"
          :items="userList"
          :ripple="false"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="620px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              @change="userCheck(item)"
              style="transform: scale(2)"
            />
          </template>
          <!-- 社員コード -->
          <template v-slot:[`item.userId`]="{ item }">
            <div style="text-align: left">{{ item.userId }}</div>
          </template>
          <!-- ユーザー名 -->
          <template v-slot:[`item.userName`]="{ item }">
            <div style="text-align: left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.userName }}
                  </div>
                </template>
                <div>{{ item.userNameKana }}</div>
              </v-tooltip>
            </div>
          </template>
          <!-- 所属 -->
          <template v-slot:[`item.officialName`]="{ item }">
            <div style="text-align: left">{{ item.officialName }}</div>
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="editUser(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>

      <!-- ナビゲーションドロワー -->
      <!-- 開閉状態バインディング(openMenu) -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- サイドメニュー -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :cntapprovalFlg="infoDialog.cntapprovalFlg"
      />
    </v-sheet>
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue"; // ナビゲーションバー
import Loading from "@/components/loading"; // ローディング画面
import sideMenu from "@/components/Menu"; // サイドメニュー
import { i18n } from "../../lang/lang.js";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import OfficeSelecter from "../businessComponent/OfficeSelecter";
import CodeSelecter from "../businessComponent/CodeSelecter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    OfficeSelecter,
    CodeSelecter,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    commonForm: {
      belongSelected: "",
    },
    // 表示名
    name: "ユーザー情報", // appConfig.SCREEN_ID.P_CNT_004,
    // メニュー
    openMenu: null,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      cntapprovalFlg: false,
    },
    // ユーザー一覧のヘッダ情報(部品要求者所属なし)
    headerItems: [
      {
        text: i18n.tc("label.lbl_select"),
        value: "check",
        width: "7%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_employeeCode"),
        value: "userId",
        width: "10%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_userName"),
        value: "userName",
        width: "15%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_expiration_start_date"),
        value: "validFrom",
        width: "15%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_expiration_end_date"),
        value: "validTo",
        width: "15%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_password_expiration_datetime"),
        value: "passwordExpirationDatetime",
        width: "15%",
        align: "center",
      },
      // 編集
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
    // ユーザー一覧のヘッダ情報(部品要求者所属あり)
    headerItems2: [
      {
        text: i18n.tc("label.lbl_select"),
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_employeeCode"),
        value: "userId",
        width: "10%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_userName"),
        value: "userName",
        width: "15%",
        align: "center",
      },
      //部品要求者所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "officialName",
        width: "22%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_expiration_start_date"),
        value: "validFrom",
        width: "14%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_expiration_end_date"),
        value: "validTo",
        width: "14%",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_password_expiration_datetime"),
        value: "passwordExpirationDatetime",
        width: "15%",
        align: "center",
      },
      // 編集
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    // ソートアイテム
    sortItem: "",
    sortOptions: {},
    // ページング幅
    u_10: false,
    involce: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 総件数
    totalCount: 0,
    // ユーザーの一覧
    userList: [],
    // 選択されたユーザの一覧
    userCheckList: [],

    // 検索ボタン押下済み
    isSelected: false,

    // 入力チェック
    rules: {},

    // 営業所プルダウン
    officeSelected: sessionStorage.getItem("sales_office_sid"),
    // ユーザ名
    userName: "",
    // 社員コード
    employeeCode: "",
    // サブ検索条件コード
    userSubSearchConditionCode: appConfig.CODETYPE.USER_MASTER_SUB_CONDITION,
    // サブ検索条件プルダウン
    userSubSearchConditionSelected: "",

    // 検索条件保持
    searchOfficeSelected: "",
    searchUserName: "",
    searchEmployeeCode: "",
    searchUserSub: "",
    searchBelongSelected: "",
    searchFlg: false,
    //部品要求者所属用フラグ
    partsRequesterAffiliationFlg: false,
  }),
  methods: {
    // 初期化
    init() {
      // 画面表示時にユーザ一覧取得はしない
      // this.getUsers();
      // 前に検索した情報が有れば初期値として登録
      if (this.$route.params.searchFlg) {
        this.officeSelected = this.$route.params.selectPram.searchOfficeSelected ?? "";
        this.userName = this.$route.params.selectPram.searchUserName ?? "";
        this.employeeCode = this.$route.params.selectPram.searchEmployeeCode ?? "";
        this.commonForm.belongSelected = this.$route.params.selectPram.searchBelongSelected ?? "";
        this.userSubSearchConditionSelected =
          this.$route.params.selectPram.userSubSearchConditionSelected ?? "";
        // this.search();
      }
      //PC制御マスタ取得
      this.getFunctionPermissions();
    },

    /**
     * 部品要求者必須情報取得
     */
    getFunctionPermissions() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // PC画面制御
      const functionPermissions = getParameter.getFunctionPermissions({
        conditions: [
          {
            // 納品日
            clientSid: "XXXXXXXXXX",
            attachResourceKey: appConfig.SCREEN_ID.P_MST_013,
            item01: "PartsRequesterAffiliation",
          },
        ],
      });
      Promise.all([functionPermissions])
        .then((result) => {
          if (result[0].find((permission) => permission.item01 == "PartsRequesterAffiliation")) {
            this.partsRequesterAffiliationFlg = true;
          } else {
            this.partsRequesterAffiliationFlg = false;
          }
          if (this.$route.params.searchFlg) {
            this.search();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 検索実行
    search() {
      this.isSelected = true;
      this.getUsers();
    },

    // ユーザー一覧の取得
    getUsers() {
      // 検索ボタンが押されていなければ取得しない（初期表示対応）
      if (!this.isSelected) {
        return;
      }

      // if (!this.$refs.form.validate()) {
      //   return;
      // }

      // 検索フラグ
      this.searchFlg = true;
      // 選択一覧の初期化
      this.userCheckList = [];
      // 全選択の初期化
      this.isAllSelected = false;

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // ページング
      config.params.reqComPaginationFlg = "0";
      config.params.reqComSortItem = this.sortItem;

      // 検索条件
      // 営業所
      if (this.officeSelected ?? "" != "") {
        config.params.officeSid = this.officeSelected;
      }
      // ユーザー名
      if (this.userName ?? "" != "") {
        config.params.userName = this.userName;
      }
      // 社員コード
      if (this.employeeCode ?? "" != "") {
        config.params.employeeCode = this.employeeCode;
      }
      // サブ検索条件
      if (this.userSubSearchConditionSelected ?? "" != "") {
        config.params.subSearchConditionCode = this.userSubSearchConditionSelected;
      }
      //部品要求者所属
      if (this.commonForm.belongSelected) {
        config.params.belongSid = this.commonForm.belongSelected;
      }

      //検索区分
      config.params.searchKbn = "1";

      // 検索条件保持
      this.searchOfficeSelected = this.officeSelected;
      this.searchUserName = this.userName;
      this.searchEmployeeCode = this.employeeCode;
      this.searchUserSub = this.userSubSearchConditionSelected;
      this.searchBelongSelected = this.commonForm.belongSelected;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet("master/users-search", config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 正常時
              // 取得件数の表示
              this.totalCount = jsonData.resCom.resComCount;
              // ユーザ情報を表示用に編集
              jsonData.resIdv.users.forEach((user) => {
                // 表示用項目のNULLを空文字に変換
                user.userName = user.userName != null ? user.userName : "";
                user.validFrom = user.validFrom ?? false ? user.validFrom.substr(0, 10) : "";
                user.validTo = user.validTo ?? false ? user.validTo.substr(0, 10) : "";
                user.passwordExpirationDatetime =
                  user.passwordExpirationDatetime ?? false
                    ? user.passwordExpirationDatetime.substr(0, 10)
                    : "";
              });
              this.userList = jsonData.resIdv.users;
            } else {
              // エラー時
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        // 全チェックボックスON
        this.isAllSelected = true;
        this.userList.forEach((user) => {
          user.check = true;
          this.userCheck(user);
        });
      } else {
        // 全チェックボックスOFF
        this.isAllSelected = false;
        this.userList.forEach((user) => {
          user.check = false;
          this.userCheck(user);
        });
      }
    },

    /**
     * ユーザが選択された
     * @param {*} item
     */
    userCheck(item) {
      var index = this.userCheckList.indexOf(item.userSid);
      if (item.check) {
        if (index == -1) this.userCheckList.push(item.userSid);
      } else {
        this.userCheckList.splice(index, 1);
      }
      this.isAllSelected = this.userList.every(function (user) {
        return user.check;
      });
    },

    /**
     * ログインQRを発行します。
     */
    loginQRIssue() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // リクエストボディを生成します。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目
      // 画面IDを設定します。
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_003;

      //所属有無
      if (this.partsRequesterAffiliationFlg) {
        body.reqIdv.belongPresence = "1";
      } else {
        body.reqIdv.belongPresence = "0";
      }

      // 固有IF項目
      // ユーザSIDリストを設定します。
      body.reqIdv.userSids = this.userCheckList;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // POST APIを実行します。
          .securePost(appConfig.API_URL.BIZ_LOGINQR, body, appConfig.APP_CONFIG)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // 署名付きURLを取得します。
              this.returnURL = jsonData.resIdv.presignedUrl;

              // URLにアクセスします。
              this.link();

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              // エラーメッセージを設定します。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              // タイトルを設定します。
              this.infoDialog.title = appConfig.DIALOG.title;

              // ダイアログを表示します。
              this.infoDialog.isOpen = true;

              // firstPageFlagを立てます。
              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            // エラーメッセージを設定します。
            this.infoDialog.message = ex;

            // タイトルを設定します。
            this.infoDialog.title = appConfig.DIALOG.title;

            // インフォダイアログを表示します。
            this.infoDialog.isOpen = true;

            // firstPageFlagを立てます。
            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          // 成功・失敗に関わらず行う処理です。
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 指定のURLへアクセスします。
     */
    link() {
      location.href = this.returnURL;
    },

    /**
     * ユーザー個別編集画面へ遷移
     */
    editUser(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_013,
        params: {
          userInfo: item,
          // 検索条件の保持
          selectPram: {
            // 営業所プルダウン
            searchOfficeSelected: this.searchOfficeSelected,
            // 検索用ユーザ名
            searchUserName: this.searchUserName,
            // 検索用社員コード
            searchEmployeeCode: this.searchEmployeeCode,
            // 所属
            searchBelongSelected: this.searchBelongSelected,
            // サブ検索条件プルダウン
            userSubSearchConditionSelected: this.searchUserSub,
          },
          // どこからの画面遷移か判別させるため
          displayFlg: "2",
          // 検索フラグ
          searchFlg: this.searchFlg,
        },
      });
    },

    /**
     * 新規登録ボタン押下
     */
    initRegist() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_013,
        params: {
          // 検索条件の保持
          selectPram: {
            // 営業所プルダウン
            searchOfficeSelected: this.searchOfficeSelected,
            // 検索用ユーザ名
            searchUserName: this.searchUserName,
            // 検索用社員コード
            searchEmployeeCode: this.searchEmployeeCode,
            // 所属
            searchBelongSelected: this.searchBelongSelected,
            // サブ検索条件プルダウン
            userSubSearchConditionSelected: this.searchUserSub,
          },
          // どこからの画面遷移か判別させるため
          displayFlg: "1",
          // 検索フラグ
          searchFlg: this.searchFlg,
        },
      });
    },

    /**
     * 画面権限一括修正ボタン押下
     */
    moveRoleMasterBatchAddUpdate() {
      //遷移
      this.$router.push({
        name: appConfig.MENU_ID.P_MST_ROLE_MASTER_BATCH_ADD_UPDATE,
        params: {
          // ユーザー一覧画面からの遷移
          manageUser: true,
        },
      });
    },
  },
  created() {},
  mounted() {
    // 作成完了時に初期化処理を行う
    this.init();
  },
  computed: {},
  watch: {
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_012_SORT[sortBy].replaceAll("{0}", ascdesc);
        }
        this.getUsers();
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.toatlNum {
  margin-left: 10px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.v-tooltip__content {
  background: rgba(97, 97, 97, 0.9);
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  position: absolute;
  text-transform: initial;
  width: auto;
  pointer-events: none;
}
</style>
