<template>
  <div class="d-flex align-start flex-nowrap">
    <v-text-field
      outline
      dense
      v-model="textFieldDate"
      :label="label"
      clear-icon="mdi-close-circle"
      clearable
      :rules="[dateRule]"
      class="date-time-picker"
    ></v-text-field>
    <span v-if="required" class="require asterisk-spacer">*</span>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="-100"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text x-small class="px-1">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>

      <DatePicker
        v-model="pickerDateTime"
        mode="dateTime"
        is24hr
        no-title
        @focusout="dateMenu = false"
      ></DatePicker>
    </v-menu>
  </div>
</template>

<script>
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import { DatePicker } from "v-calendar";

export default {
  name: "DateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    value: String,
    label: String,
    required: { type: Boolean, default: false, required: false },
  },

  computed: {
    textFieldDate: {
      get() {
        return this.$props.value?.replaceAll("-", "/") ?? "";
      },

      set(newDate) {
        this.$emit("input", newDate?.replaceAll("/", "-") ?? "");
      },
    },

    pickerDateTime: {
      get() {
        return this.$props.value ?? "";
      },

      set(newDate) {
        this.$emit("input", newDate);
      },
    },
  },

  data() {
    return {
      dateMenu: false,
      errorMessage: "yyyy/MM/dd HH:mmで入力してください。",
      dateRule: (value) => this.validRule(value) || this.errorMessage,
    };
  },

  methods: {
    validRule(time) {
      if (!time) return this.required ? false : true;
      let strDate = time.slice(0, 10);
      let strTime = time.slice(11);
      const isDate =
        dateTimeHelper.validDate(commonUtil.zen2han(strDate), "/") &&
        dateTimeHelper.isDate(strDate);
      const isTime = dateTimeHelper.validTime(commonUtil.zen2han(strTime), ":");
      return isDate && isTime;
    },
  },
};
</script>
