<template>
  <v-dialog v-model="routeDialog" width="80vw" :retain-focus="false">
    <template v-if="openLabel == ''" v-slot:activator="{ on, attrs }">
      <v-btn :disabled="disabled" v-bind="attrs" v-on="on" icon>
        <v-icon color="black">mdi-map-marker</v-icon>
      </v-btn>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        outlined
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        class="mb-0 text-overline"
      >
        {{ openLabel }}
      </v-btn>
    </template>

    <v-card max-height="90vh">
      <v-toolbar dense>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="routeDialog = false"><v-icon> mdi-close </v-icon></v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-row no-gutters>
        <v-col cols="3" class="overflow-y-auto" style="height: 80vh">
          <slot name="default"></slot>
        </v-col>

        <v-col>
          <GoogleEmbedMap mapMode="directions" :params="mapParams" width="100%" height="80vh" />
        </v-col>
        <v-col cols="1">
          <v-switch v-model="useTolls" label="有料道路"></v-switch>
          <v-switch v-model="useHighways" label="高速道路"></v-switch>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleEmbedMap from "./GoogleEmbedMap.vue";

export default {
  name: "PickupDeliveryMapDialog",
  components: {
    GoogleEmbedMap,
  },
  props: {
    dialogTitle: { type: String, default: "" },
    openLabel: { type: String, default: "" },
    origin: { type: String, default: "" },
    destination: { type: String, default: "" },
    waypoints: { type: Array, default: () => [] },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      useTolls: false,
      useHighways: false,
      routeDialog: false,
    };
  },
  computed: {
    mapParams() {
      const avoids = ["ferries"];
      if (!this.useTolls) {
        avoids.push("tolls");
      }
      if (!this.useHighways) {
        avoids.push("highways");
      }

      return {
        origin: this.origin,
        destination: this.destination,
        waypoints: this.waypoints,
        avoid: avoids,
      };
    },
  },
};
</script>
