<template>
  <v-dialog v-model="searchCondition" scrollable width="60vw">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small outlined :disabled="disabled" v-bind="attrs" v-on="on" class="mb-2">
        {{ openLabel }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title style="height: 60px">
        {{ dialogTitle }}
        <v-spacer></v-spacer>
        <v-btn icon @click="searchCondition = false"><v-icon> mdi-close </v-icon></v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="max-height: 80vh">
        <div class="sort-area">
          <div class="sort-condition-header">
            <v-card :elevation="0" color="amber lighten-5" width="100%">
              <v-card-title>ソート項目</v-card-title>
            </v-card>
          </div>
          <v-row dense class="pt-4">
            <v-col
              cols="6"
              v-for="n in sortLimit"
              :key="'sort' + n"
              class="d-flex align-buttom px-4"
            >
              <v-row no-gutters>
                <v-col cols="9" class="pr-2">
                  <v-select
                    :value="selectedSortItems.get(n)?.property"
                    @change="(value) => changeSortItem(n, value)"
                    :items="sortItems"
                    :label="`第${n}ソート`"
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col col="2">
                  <v-select
                    :value="selectedSortItems.get(n)?.order ?? 'asc'"
                    :items="sortOrderItems"
                    @change="(value) => changeSortOrder(n, value)"
                    dense
                    solo
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="condition-area">
          <div class="sort-condition-header">
            <v-card elevation="0" color="amber lighten-5" width="100%">
              <v-card-title>絞込み項目</v-card-title>
            </v-card>
          </div>
          <table class="condition-table mt-2">
            <tbody v-for="(filter, index) in filters" :key="index">
              <tr row>
                <th v-if="filter.type === 'number'">
                  {{ filter.label + "(" + filter.unit + ")" }}
                </th>
                <th v-else>{{ filter.label }}</th>
                <!-- 自由入力項目 -->
                <td v-if="filter.type === 'text'" style="width: 80%; margin-left: 10px">
                  <v-text-field
                    :value="selectedValueObj[filter.name]"
                    :label="filter.label"
                    @change="(value) => changeSelectedValueObj(filter.name, value)"
                    class="txt-single"
                    clear-icon="mdi-close-circle"
                    clearable
                  >
                  </v-text-field>
                </td>
                <!-- 数値入力 -->
                <td v-if="filter.type === 'number'" style="width: 250px; margin-left: 10px">
                  <v-text-field
                    :value="selectedValueObj[filter.name]"
                    :label="filter.label"
                    @change="(value) => changeSelectedValueObj(filter.name, value)"
                    :rules="[rules.isNumber, rules.isRangeCheck]"
                    class="txt-single"
                    clear-icon="mdi-close-circle"
                    style="width: 200px"
                    clearable
                    outlined
                    @update:error="
                      validationErrors = { ...validationErrors, [filter.name]: $event }
                    "
                  >
                  </v-text-field>
                  <p>{{ filter.unit + "以下" }}</p>
                </td>
                <!-- ラジオボタン -->
                <td v-if="filter.type === 'radio'" style="margin-left: 10px">
                  <v-radio-group
                    :value="selectedValueObj[filter.name]"
                    @change="(value) => changeSelectedValueObj(filter.name, value)"
                    row
                  >
                    <v-radio
                      v-for="{ text, value } in filter.values"
                      :key="value"
                      :label="text ?? value"
                      :value="value"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <!-- プルダウン -->
                <td v-if="filter.type === 'select'" style="margin-left: 10px">
                  <v-select
                    :value="selectedValueObj[filter.name]"
                    :items="filter.values"
                    :label="filter.label"
                    :multiple="filter.multi"
                    @change="(value) => changeSelectedValueObj(filter.name, value)"
                    clearable
                  >
                    <template slot="item" slot-scope="data">
                      <span class="test"> {{ data.item.text }} </span>
                    </template>
                  </v-select>
                </td>
                <!-- 入力プルダウン -->
                <td v-if="filter.type === 'autocomplete'" style="margin-left: 10px">
                  <v-autocomplete
                    :items="filter.group ? toItemValue(filter.groupValues) : filter.values"
                    :label="filter.label"
                    :multiple="filter.multi"
                    :value="selectedValueObj[filter.name]"
                    chips
                    deletable-chips
                    @change="(value) => changeSelectedValueObj(filter.name, value)"
                  >
                  </v-autocomplete>
                </td>
                <!-- 日付入力 -->
                <td v-if="filter.type === 'date'" style="margin-left: 10px">
                  <template v-if="filter.range === 'true'">
                    <!-- from -->
                    <DatePicker
                      :value="selectedValueObj[filter.name + '.from']?.replaceAll('/', '-')"
                      :label="filter.label + '(from)'"
                      :clearable="false"
                      :required="filter.required"
                      @input="(value) => changeSelectedValueObj(filter.name + '.from', value)"
                      @update:error="
                        validationErrors = { ...validationErrors, [filter.name]: $event }
                      "
                    >
                    </DatePicker>
                    <!-- カレンダーの間 -->
                    <div class="from_to">～</div>
                    <!-- to -->
                    <DatePicker
                      :value="selectedValueObj[filter.name + '.to']?.replaceAll('/', '-')"
                      :label="filter.label + '(to)'"
                      :clearable="false"
                      :required="filter.required"
                      @input="(value) => changeSelectedValueObj(filter.name + '.to', value)"
                      @update:error="
                        validationErrors = { ...validationErrors, [filter.name]: $event }
                      "
                    >
                    </DatePicker>
                  </template>
                  <template v-else>
                    <DatePicker
                      :value="selectedValueObj[filter.name]?.replaceAll('/', '-')"
                      :label="filter.label"
                      :clearable="false"
                      :required="true"
                      @input="(value) => changeSelectedValueObj(filter.name, value)"
                      @update:error="
                        validationErrors = { ...validationErrors, [filter.name]: $event }
                      "
                    >
                    </DatePicker>
                  </template>
                </td>
                <!-- 日時入力 -->
                <td v-if="filter.type === 'datetime'">
                  <v-textarea
                    counter="200"
                    label="備考"
                    :rules="rules"
                    :value="value"
                    placeholder="便に関する備考を入力してください。"
                    @update:error="
                      validationErrors = { ...validationErrors, [filter.name]: $event }
                    "
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions dense style="height: 60px">
        <v-btn color="primary" elevation="2" fixed @click="search" :disabled="hasError">
          絞り込み検索
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import commonRules from "@/mixins/CommonRules";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";

import DatePicker from "@/components/DatePicker.vue";

export default {
  name: "SearchCondition",
  components: {
    DatePicker,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
    dialogTitle: { type: String, default: "" },
    openLabel: { type: String, default: "" },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: { type: Array, default: () => [] },
    sortItemLimit: { type: Number },
    sortItems: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedItems: [],
      selectedValueObj: {},
      selectedSortItems: new Map(),
      // 初期データ
      defaultData: [],
      searchCondition: false,
      sortOrderItems: [
        {
          text: this.$t("label.lbl_ascending_order"),
          value: "asc",
        },
        {
          text: this.$t("label.lbl_descending_order"),
          value: "desc",
        },
      ],
      validationErrors: {},
    };
  },
  created() {
    const map = new Map();
    for (let n = 1; n <= this.sortLimit; n++) {
      const item = {
        property: undefined,
        order: "asc",
      };
      map.set(n, item);
    }
    this.selectedSortItems = map;
  },
  mounted() {
    this.init();
  },
  computed: {
    sortLimit() {
      if (!this.sortItemLimit) {
        return this.sortItems.length;
      }

      return this.sortItemLimit > this.sortItems.length
        ? this.sortItems.length
        : this.sortItemLimit;
    },
    hasError() {
      return Object.values(this.validationErrors).some((value) => value);
    },
  },
  watch: {
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    filters: function (newValue) {
      const obj = {};
      for (const { name, range, multi, initialValue, initialValues } of newValue ?? []) {
        if (range === "true" || range === "timeonly") {
          const from = initialValues?.[0].length
            ? dateTimeHelper.convertUTC2JST(initialValues[0]).split(" ")[0]
            : "";
          const to = initialValues?.[1].length
            ? dateTimeHelper.convertUTC2JST(initialValues[1]).split(" ")[0]
            : "";
          obj[name + ".from"] = this.selectedValueObj?.[name + ".from"] || from;
          obj[name + ".to"] = this.selectedValueObj?.[name + ".to"] || to;
        } else if (multi) {
          const values = this.selectedValueObj?.[name] ?? [];
          obj[name] = (values.length ? values : initialValues) ?? [];
        } else {
          obj[name] = this.selectedValueObj?.[name] || (initialValue ?? "");
        }
      }
      this.selectedValueObj = obj;
      this.emitCondition();
    },
    sortItems: function () {
      const map = new Map();
      for (let n = 1; n <= this.sortLimit; n++) {
        const item = {
          property: undefined,
          order: "asc",
        };
        map.set(n, item);
      }
      this.selectedSortItems = map;
    },
  },
  methods: {
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
    },
    toItemValue(groupValues) {
      const items = [];
      for (const { values } of groupValues) {
        if (items.length !== 0) {
          items.push({ divider: true });
        }
        items.push(...values);
      }
      return items;
    },
    changeSelectedValueObj(name, value) {
      this.selectedValueObj = {
        ...this.selectedValueObj,
        [name]: value,
      };
    },
    changeSortItem(num, property) {
      const item = this.selectedSortItems.get(num);
      const newItem = { ...item, property };
      this.selectedSortItems.set(num, newItem);
    },
    changeSortOrder(num, order) {
      const item = this.selectedSortItems.get(num);
      const newItem = { ...item, order };
      this.selectedSortItems.set(num, newItem);
    },
    emitCondition() {
      const selected = {};
      for (const [name, value] of Object.entries(this.selectedValueObj)) {
        if (value?.length) {
          selected[name] = value;
        }
      }
      this.$emit("change:condition", selected);
    },
    emitSort() {
      const sorts = [];
      for (let n = 1; n <= this.sortLimit; n++) {
        const item = this.selectedSortItems.get(n);
        if (!!item.property && !!item.order) {
          if (sorts.every((sort) => sort.property !== item.property)) {
            sorts.push(item);
          }
        }
      }
      this.$emit("change:sort", sorts);
    },
    search() {
      this.emitCondition();
      this.emitSort();
      this.searchCondition = false;
      this.$emit("click");
    },
  },
};
</script>
<style>
@import "../../css/style.css";

/*
 * 共通スタイルの影響でv-selectにフォーカス中は、選択中の項目の削除ボタンがの非表示になってしまうため、
 * 削除ボタンを常時表示するため、スタイルを上書きする
 */
table.condition-table td .v-input--is-focused button.mdi-close-circle {
  display: inline-flex !important;
}

.v-input--is-focused .mdi-close-circle .condition-table tr:focus-within td .mdi-close-circle {
  display: inline-flex !important;
}
</style>
<style lang="scss" scoped>
.condition-table {
  width: 100%;
}

.condition-table tr {
  height: 70px;
}

.condition-table th {
  width: 20%;
  padding: 15px 20px;
  background: #eeeeee;
  vertical-align: middle;
  font-weight: bold;
}

.condition-table td {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

ul,
li {
  list-style-position: outside;
  vertical-align: bottom;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

.sort-condition-header {
  display: flex;
  justify-content: center;
}
</style>
