<template>
  <v-dialog
    v-model="binRemarksInputDialog"
    width="60vw"
    height="90vh"
    :retain-focus="false"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="disabled" v-bind="attrs" v-on="on" icon>
        <v-icon v-if="`${remarks}` != ''" color="black">mdi-note-edit</v-icon>
        <v-icon v-else color="grey">mdi-note-edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="binRemarksInputDialog = false"><v-icon> mdi-close </v-icon></v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <div dense style="height: auto">
        <slot name="default"></slot>
        <v-container fluid>
          <v-text-field
            v-model="inputRemarks"
            maxlength="100"
            :label="$t('label.lbl_remarks')"
            placeholder="便に関する備考を入力してください。"
            :rules="rules"
          ></v-text-field>
        </v-container>
      </div>

      <v-divider></v-divider>

      <v-toolbar dense>
        <v-btn color="primary" elevation="2" @click="changeRemarks()" style="margin: 0 0 0 auto"
          >OK
        </v-btn>
        <v-btn color="light-grey" elevation="2" @click="cancelInput()" style="margin: 0 0 0 20px"
          >キャンセル
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { i18n } from "../../lang/lang.js";

export default {
  name: "BinRemarksInputDialog",
  components: {},
  props: {
    dialogTitle: { type: String, default: "" },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    carSid: { type: String, default: "" },
    transportSid: { type: String, default: "" },
    remarks: { type: String, default: "" },
  },
  data() {
    return {
      binRemarksInputDialog: false,
      inputRemarks: this.remarks,
      rules: [(v) => v.length <= 200 || i18n.tc("check.chk_remarks_limit")],
    };
  },
  computed: {},
  methods: {
    changeRemarks() {
      this.$emit("change-remarks", this.carSid, this.transportSid, this.inputRemarks);
      this.binRemarksInputDialog = false;
    },
    cancelInput() {
      this.inputRemarks = this.remarks;
      this.binRemarksInputDialog = false;
    },
  },
};
</script>
