<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-app-bar id="sub-bar" dense elevation="2">
        <div id="out-screen-name">{{ $t("label.lbl_inquiryList") }}</div>
      </v-app-bar>
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row" align="center">
            <!--ボタン領域-->
            <v-col class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getFreightConditions()">{{
                  $t("btn.btn_freightInquiry")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </v-col>
            <v-spacer></v-spacer>

            <v-img
              id="company-logo"
              alt="company Logo"
              class="shrink mr-2"
              contain
              src="../../assets/img/nikkon_logo.png"
              transition="scale-transition"
              width="50"
            />
            <v-img
              id="company-name-logo"
              alt="company Name Logo"
              class="shrink mr-2"
              contain
              src="../../assets/img/nikkon_name_logo.png"
              transition="scale-transition"
              width="250"
            />
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid class="main-area">
        <v-data-table
          id="inquiryListData"
          fixed-header
          :headers="headerItems"
          :items="freightProgressListForInvNum"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
        >
          <!-- 送り状番号 -->
          <template v-slot:[`item.invNumSid`]="{ item }">
            <div>
              <v-text-field
                outlined
                dense
                v-model="item.invNumSid"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
          </template>

          <!-- 問合せ番号 -->
          <template v-slot:[`item.inquiryNo`]="{ item }">
            <div>{{ item.inquiryNo }}</div>
          </template>

          <!-- 便情報一覧 -->
          <template v-slot:[`item.progress`]="{ item }">
            <ProgressItem
              :progress="item.progress"
              @input-slider="sliderHandleInput"
            ></ProgressItem>
          </template>
        </v-data-table>

        <!-- ダイアログ -->
        <v-dialog v-model="resultDialog.isOpen" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ resultDialog.title }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p class="dialig-text">{{ resultDialog.message }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="ok"> OK </v-btn>
              <v-btn v-if="resultDialog.cancelBtnFlg" @click="close()">
                {{ $t("btn.btn_cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import Loading from "@/components/loading";
import commonRules from "@/mixins/CommonRules";
import ProgressItem from "./ProgressItem.vue";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";

export default {
  name: "InquiryList",
  components: {
    Loading,
    ProgressItem,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
    inputNum: String,
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    openMenu: false,
    radioSelected: "",
    // 一覧
    freightProgressListForInvNum: [],
    // ヘッダ
    headerItems: [
      // 件数
      { text: "", value: "no", width: "85px", align: "left", sortable: false },
      // 送り状番号
      {
        text: i18n.tc("label.lbl_invNumSid"),
        value: "invNumSid",
        width: "275px",
        align: "left",
        sortable: false,
      },
      // お客様問合せ番号
      {
        text: i18n.tc("label.lbl_inquiryNo"),
        value: "inquiryNo",
        width: "275px",
        align: "left",
        sortable: false,
      },
      // progressBar
      { text: "", value: "progress", minwidth: "600px", align: "left", sortable: false },
    ],
    initInquiryData: {
      pickupScheduleDate: "",
      deliveryScheduleDate: "",
      smallLotsQuantity: "",
      scaleLabels: {},
      statusNo: "0",
      totalScaleNo: 0,
    },
    resultDialog: {
      isOpen: false,
      message: "",
      title: "",
      cancelBtnFlg: false,
      okAction: () => {},
    },
  }),

  created() {},

  methods: {
    /**
     * 初期化
     */
    init() {
      this.invNumInit();
    },
    invNumInit() {
      // 初期表示は固定で10要素を指定
      const initList = [...Array(10)].map((_, i) => ({
        no: i + 1 + "件目",
        invNumSid: "",
        inquiryNo: "",
        progress: this.initInquiryData,
      }));
      this.freightProgressListForInvNum = initList;
    },
    // つまみの進捗を設定
    sliderHandleInput(handleValue) {
      this.value = handleValue;
    },
    /**
     * 貨物状況取得
     */
    getFreightConditions() {
      // テキストボックスの値取得
      const invNumList = this.freightProgressListForInvNum
        .map((el) => el.invNumSid)
        .filter((value) => value && value.trim() !== "");
      // 送り状に入力がない場合画面を初期化してメッセージを返却
      if (invNumList.length === 0) {
        this.resultDialog.message = messsageUtil.getMessage("P-TRN-001_002_E");
        this.resultDialog.isOpen = true;
        this.resultDialog.title = appConfig.DIALOG.confirm;
        this.resultDialog.cancelBtnFlg = false;
        this.resultDialog.okAction = this.close;
        this.invNumInit();
        return;
      }
      // APIから値取得
      // ---------------------------------------------------------------------
      const commonConfig = this.$httpClient.createGetApiRequestConfig();
      const config = {};
      config.params = {
        // 送り状番号リストを設定
        invNumSidList: invNumList,
        // 処理時間、言語CDを設定
        execTimestamp: commonConfig.params.reqComExecTimestamp,
        languageCd: commonConfig.params.reqComLanguageCd,
      };
      // 接続先のAPI_URLを入力
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_PICKUP_DELIVERY_STATUTS_SEARCH, config)
          .then((response) => {
            const data = response.data;
            if (data.resCom.resComCode !== "000") {
              const message =
                data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
            const progressData = data.resIdv.pickupDeliveryStatusList;

            this.invNumInit();
            // 入力された送り状ごとに処理
            for (let i = 0; i < invNumList.length; i++) {
              // 送り状番号を検索
              const inquiryData = progressData.find(({ invNumSid }) => invNumSid === invNumList[i]);

              // 一致する送り状番号がある場合データを設定
              if (inquiryData) {
                this.freightProgressListForInvNum[i].invNumSid = invNumList[i];
                this.freightProgressListForInvNum[i].inquiryNo = inquiryData.inquiryNumber;
                this.freightProgressListForInvNum[i].progress =
                  this.formatProgressData(inquiryData);
              }
              // 一致する送り状番号がない場合、問合せ番号・進捗にメッセージを表示
              else if (invNumList[i].invNumSid) {
                this.freightProgressListForInvNum[i].invNumSid = invNumList[i];
                this.freightProgressListForInvNum[i].inquiryNo = "";
                this.freightProgressListForInvNum[i].progress = {
                  invNumSid: invNumList[i], // 送り状番号
                  inquiryNo: "", // 問合せ番号
                  exsistMessage: messsageUtil.getMessage("P-TRN-001_001_E"),
                  totalScaleNo: 0, // ラベルの総数
                };
              }
              // 空白の場合、問合せ番号・進捗を初期化
              else {
                this.freightProgressListForInvNum[i].inquiryNo = "";
                this.freightProgressListForInvNum[i].progress = this.initInquiryData;
              }
            }
            resolve(response);
          })
          .catch((ex) => {
            this.resultDialog.message = ex.message ?? ex;
            this.resultDialog.isOpen = true;
            this.resultDialog.title = appConfig.DIALOG.confirm;
            this.resultDialog.cancelBtnFlg = false;
            this.resultDialog.okAction = this.close;
            reject();
          });
      });
    },
    /**
     * 文字列型の日時をUTCからJSTへ変更し、指定したフォーマットを行う
     */
    formatDate(date, format) {
      return date
        ? dateTimeHelper.toStringDate(
            format,
            dateTimeHelper.convertJstDateTime(dateTimeHelper.convertUTC2JST(date))
          )
        : null;
    },

    /**
     * 進捗状況実績を表示データとしてフォーマット
     */
    formatProgressData(inquiryData) {
      let progressData = {
        invNumSid: inquiryData.invNumSid, // 送り状番号
        inquiryNo: inquiryData.inquiryNumber, // 問合せ番号
        pickupScheduleDate: this.formatDate(inquiryData.pickupScheduleDate, "YYYY/MM/DD"), // 集荷予定日
        deliveryScheduleDate: this.formatDate(inquiryData.deliveryScheduleDate, "YYYY/MM/DD"), // 配達予定日
        smallLotsQuantity: inquiryData.smallLotsQuantity, // 小口数
        message: inquiryData.message,
        statusNo: inquiryData.pickupDeliveryActList?.length - 1 ?? 0, // 進捗ポイント（アイコンがどこまで進むか）
        scaleLabels: inquiryData.statusLabels,
        totalScaleNo: inquiryData.statusLabels.length - 1, // ラベルの総数
      };
      for (let pickupDeliveryAct of inquiryData.pickupDeliveryActList) {
        pickupDeliveryAct.actualDtm = this.formatDate(
          pickupDeliveryAct.actualDtm,
          "YYYY/MM/DD hh:mm"
        );
      }
      progressData.pickupDeliveryActList = inquiryData.pickupDeliveryActList;
      return progressData;
    },
    /**
     * ダイアログのOKボタン押下時
     */
    ok() {
      this.resultDialog.okAction();
    },
    /**
     * ダイアログを閉じるアクション
     */
    close() {
      this.resultDialog.isOpen = false;
      this.resultDialog.cancelBtnFlg = false;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.resultDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.resultDialog.isOpen = true;
      this.resultDialog.title = appConfig.DIALOG.confirm;
      this.resultDialog.cancelBtnFlg = true;
      this.resultDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期化
      this.init();
      this.close();
    },
  },

  computed: {},
  watch: {},
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#inquiryListData ::v-deep {
  .v-data-table__wrapper {
    overflow-x: hidden;
  }
  th,
  td {
    padding: 7px 20px;
  }
  th:last-child {
    min-width: 600px;
  }
}
.table-container {
  max-height: calc(100vh - 50px);
  display: block;
}
.table-container .v-data-table {
  overflow: hidden; /* 横スクロールを非表示にする（必要に応じて調整） */
}
#out-screen-name {
  padding: 0.1rem 0 0 0.5rem;
  font-size: x-large;
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
  margin-top: 10px;
}
</style>
